$(document).on('render:papers', () => {
  document.querySelector('.back_btn').style.display='flex'
  if($('body').attr('data-action') != 'index'){
    let patentImgs = document.querySelectorAll('.patent_img img')
    let softwareImgs = document.querySelectorAll('.software_box img')
    let bigImg = document.querySelector('.bigImg')
    let g_phont = document.querySelector('.g_phont')
    let g_right = document.querySelector('.g_right')
    patentImgs.forEach((item) => {
      item.onclick = function () {
        g_phont.style.display = 'block'
        bigImg.src = item.src
        document.body.style.overflow = 'hidden';
      }
    })
    softwareImgs.forEach((item) => {
      item.onclick = function () {
        bigImg.src = item.src
        g_phont.style.display = 'block'
        document.body.style.overflow = 'hidden';
      }
    })
    g_right.onclick = function () {
      g_phont.style.display = 'none'
      document.body.style.overflow = '';
    }
  }
})