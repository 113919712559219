import 'jquery';
import 'fullpage.js';
import './desktop';


require.context('../images', true)
require.context('../videos', true)

$(document).ready(() => {
  const page = document.body.dataset.page
  const action = document.body.dataset.action
  if (page) {
    $(document).trigger(`render:${page}`)
  }


  // pc导航栏
  let nav_two = document.querySelectorAll('.nav_two')
  let head_search = document.querySelector('header .search')
  let seacrh_Img = document.querySelector('header .search_inp img')
  let seach_Url = seacrh_Img.src
  head_search.addEventListener('mouseover', () => {
    seacrh_Img.src = seacrh_Img.dataset.egUrl
  })
  head_search.addEventListener('mouseout', () => {
    seacrh_Img.src = seach_Url

  })
  //搜索
  let search_box = document.querySelectorAll('.search_box')
  search_box.forEach(v => {
    v.onmousedown = function (e) {
      e.preventDefault()
      return false;
    }
  })

  // 移动端导航栏
  let h_close = document.querySelector('.h_close')
  let h_tag = document.querySelector('.h_tag')
  let h_search = document.querySelector('.h_search')
  let head_list = document.querySelector('.head_list')
  let head_top = document.querySelector('.head_top')
  let md_search = document.querySelector('.md_search')
  let h_top = document.querySelector('.h_top')
  let h_bool = true
  let h_close_url = h_close.src

  // 移动端目录
  h_close.onclick = function () {
    if (h_bool) {
      md_search.style.display = 'none'
      head_list.style.display = 'flex'
      h_close.src = h_close.dataset.egUrl
      h_top.classList.add('h_top_active')
      h_tag.style.display = 'none'
      h_search.style.display = 'none'
      h_close.style.marginLeft = '1.6rem'
      h_bool = false
    } else {
      head_list.style.display = 'none'
      md_search.style.display = 'none'
      h_close.src = h_close_url
      h_top.classList.remove('h_top_active')
      h_tag.style.display = 'block'
      h_search.style.display = 'block'
      h_close.style.marginLeft = '0'
      h_tag.style.marginLeft = '0'
      h_bool = true
    }
  }

  // 移动端导航展开
  let left_li = document.querySelectorAll('.head_list .left_item')
  let right_li = document.querySelectorAll('.head_list .head_right>li')
  left_li.forEach((item, index) => {
    item.onclick = function () {
      left_li.forEach((v, i) => {
        right_li[i].className = ''
        v.className = ''
      })
      right_li[index].className = 'active'
      item.className = 'active'
    }
  })

  let bom_tag = document.querySelectorAll('.bom_tag')
  let bom_img = document.querySelectorAll('.bom_img')
  let dt = document.querySelectorAll('dt')
  let bomUrl = bom_img[0].src
  bom_tag.forEach((item, index) => {
    let bomBool = true
    item.onclick = function (e) {
      e.stopPropagation();
      if (bomBool) {
        bom_img[index].src = bom_img[index].dataset.egUrl
        dt[index].className = 'active'
        bomBool = false
      } else {
        bom_img[index].src = bomUrl
        dt[index].className = ''
        bomBool = true
      }
    }
  })


  // 移动端搜索栏
  h_search.onclick = function () {
    md_search.style.display = 'block'
    head_list.style.display = 'none'
    h_close.src = h_close.dataset.egUrl
    h_top.classList.add('h_top_active')
    h_tag.style.display = 'block'
    h_search.style.display = 'none'
    h_tag.style.marginLeft = '0.8rem'
    h_bool = false
  }

  // 二维码
  let chat_Img = document.querySelectorAll('.chat_img img')
  let icon_Img = document.querySelectorAll('.chat_icon img')
  icon_Img.forEach((v, i) => {
    let conUrl = v.src
    v.addEventListener('mouseover', () => {
      v.src = v.dataset.egUrl
      chat_Img[i].style.display = 'block'
    })
    v.addEventListener('mouseout', () => {
      v.src = conUrl
      chat_Img[i].style.display = 'none'
    })
  })


  // 移动端二维码
  let md_code = document.querySelector('footer .md_foot .md_code')
  let right_code = document.querySelector('#rightcode')
  md_code.innerHTML = right_code.outerHTML

  // 全屏尾部插入
  $('.welcome .foot_title').after($('footer')[0])
  $('.abouts .fp-auto-height').append($('footer')[0])


  let md_chat_Img = document.querySelectorAll('footer .md_foot .chat_img img')
  let md_icon_Img = document.querySelectorAll('footer .md_foot .chat_icon img')
  md_icon_Img.forEach((v, i) => {
    v.src = v.dataset.codeUrl
    let conUrl = v.dataset.codeUrl
    v.addEventListener('mouseover', () => {
      v.src = v.dataset.egUrl
      md_chat_Img[i].style.display = 'block'
    })
    v.addEventListener('mouseout', () => {
      v.src = conUrl
      md_chat_Img[i].style.display = 'none'
    })
  })



  var offsetWidth = document.documentElement.clientWidth;
  if (offsetWidth < 1080) {
    // 首页
    $('.about_data,.about,.title-img,.news_left_swiper,.news_left_img,.pointList,.lists_items,.honour_title img').removeClass(
      'animate__animated')

    // 关于我们
    $('.common_title,.info,.g_va,.introduce_data,.structure_bg,.structure_info,.switch_top,.switch_bom,.v-flex-v,.vision_title p,.vision_list li,.activity ,.activity_swiper,.activity_list li,.honour_content,.letters_title').removeClass(
      'animate__animated')

    // 尾部二维码
    icon_Img.forEach(v => {
      v.src = v.dataset.codeUrl
      v.addEventListener('mouseout', () => {
        v.src = v.dataset.codeUrl
      })
    })

  } else {
    $('.about_data,.about,.title-img,.news_left_swiper,.news_left_img,.pointList,.lists_items,.honour_title img').addClass(
      'animate__animated')

    $('.common_title,.info,.g_va,.introduce_data,.structure_bg,.structure_info,.switch_top,.switch_bom,.v-flex-v,.vision_title p,.vision_list li,.activity ,.activity_swiper,.activity_list li,.honour_content,.letters_title').addClass(
      'animate__animated')
  }

  const getWindowInfo = () => {
    const windowInfo = {
      width: window.innerWidth,
    }
    if (windowInfo.width < 1080) {
      //首页
      $('.about_data,.about,.title-img,.news_left_swiper,.news_left_img,.pointList,.lists_items,.honour_title img').removeClass(
        'animate__animated')
      // 关于我们

      $('.common_title,.info,.g_va,.introduce_data,.structure_bg,.structure_info,.switch_top,.switch_bom,.v-flex-v,.vision_title p,.vision_list li,.activity ,.activity_swiper,.activity_list li,.honour_content,.letters_title').removeClass(
        'animate__animated')

      // 尾部二维码
      icon_Img.forEach(v => {
        v.src = v.dataset.codeUrl
        v.addEventListener('mouseout', () => {
          v.src = v.dataset.codeUrl
        })
      })

    } else {
      // 首页
      $('.about_data,.about,.title-img,.news_left_swiper,.news_left_img,.pointList,.lists_items,.honour_title img').addClass(
        'animate__animated')
      // 关于我们
      $('.common_title,.info,.g_va,.introduce_data,.structure_bg,.structure_info,.switch_top,.switch_bom,.v-flex-v,.vision_title p,.vision_list li,.activity ,.activity_swiper,.activity_list li,.honour_content,.letters_title').addClass(
        'animate__animated')
      head_top.style.background = ''
    }
  };

  const debounce = (fn, delay) => {
    let d_timer;
    return function () {
      if (d_timer) {
        clearTimeout(d_timer);
      }
      d_timer = setTimeout(() => {
        fn();
      }, delay);
    }
  };
  const cancalDebounce = debounce(getWindowInfo, 500);
  window.addEventListener('resize', cancalDebounce);

  // 客服
  let cusitem = document.querySelectorAll('.cus_item')
  let cus_imgs = document.querySelectorAll('.cus_item img')
  let cus_spans = document.querySelectorAll('.cus_item span')
  let back_btn = document.querySelector('.back_btn')
  for (let index = 0; index < cusitem.length; index++) {
    cusitem[index].addEventListener('mouseover', () => {
      cus_imgs[index].style.display = 'none'
      cus_spans[index].style.display = 'block'
    })
    cusitem[index].addEventListener('mouseout', () => {
      cus_imgs[index].style.display = 'block'
      cus_spans[index].style.display = 'none'
    })
  }
  // 返回上一页
  back_btn.addEventListener('click', () => {
    window.history.go(-1)
  })


  // 点我咨询
  // let zx_btn = document.querySelectorAll('.zx_btn')
  // zx_btn.forEach(v => {
  //   v.onclick = function () {
  //     window.location.href = 'https://lzt.zoosnet.net/LR/Chatpre.aspx?id=LZT14628743&lng=cn'
  //   }
  // })

  // 售后客服
  // let sh_btn = document.querySelector('.sh_btn')
  // let sh_img = document.querySelector('.sh_img')
  // sh_btn.onclick = function () {
  //   sh_img.style.display = 'flex'
  //   let shTimer = null
  //   clearTimeout(shTimer)
  //   shTimer = setTimeout(() => {
  //     sh_img.style.display = 'none'
  //   }, 3000);
  // }

  // 返回顶部
  function scrollFunction() {
    if (document.body.scrollTop > 900 || document.documentElement.scrollTop > 900) {
      cusitem[cusitem.length - 1].style.display = "block";
    } else {
      cusitem[cusitem.length - 1].style.display = "none";
    }
  }
  // 首页和关于我们
  if ((page == 'welcome' && action == 'index') || (page == 'abouts' && action == 'show')) {
    cusitem[cusitem.length - 1].addEventListener('click', () => {
      window.location.href = '#page1'
    })
  } else {
    cusitem[cusitem.length - 1].addEventListener('click', () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    })
  }

  if ((page == 'welcome' && action == 'index')) {
    head_top.onmouseover = function () {
      head_top.style.background = 'transparent'
    }
  }
  if ((page == 'abouts' && action == 'project_message')) {
    head_top.style.display = 'none'
  }
  // 滚动显示导航背景
  window.onscroll = function () {
    scrollFunction()
    var t = document.documentElement.scrollTop || document.body.scrollTop;
    var head_Top = document.querySelector('.head_top');
    if (t >= 100) {
      head_Top.style.background = '#fff'
    } else {
      head_Top.style.background = ''
      head_Top.style.boxShadow = ''
    }
  }

  // 联系我们插入
  // $('#about .section6 .fp-overflow').append($('#contact .pc_contactus')[0])
  // $('#about_md .md6').append($('#contact .md_contactus')[0])

  // 联系我们单页
  // let contact=document.querySelector('#contact')
  // if(page=='abouts'){
  //   contact.style.display='block';
  // }else{
  //   contact.style.display='none';
  // }

  // 移动端6屏点击交互
  let contactus_img = document.querySelectorAll('.md_contactus .contactus_list li img')
  let contactus_img_a = []
  let contactus_img_b = []
  contactus_img.forEach((v, i) => {
    contactus_img_a.push(v.src)
    contactus_img_b.push(v.dataset.egUrl)
    v.addEventListener('mouseover', () => {
      v.src = contactus_img_b[i]
    })
    v.addEventListener('mouseout', () => {
      v.src = contactus_img_a[i]
    })
  })


  // 尾部图标
  let info_Imgs = document.querySelectorAll('.info_imgs img')
  info_Imgs.forEach((v, i) => {
    let conUrl = v.src
    v.addEventListener('mouseover', () => {
      v.src = v.dataset.egUrl
    })
    v.addEventListener('mouseout', () => {
      v.src = conUrl
    })
  })
  let flash = document.querySelector('.flash')
  if (flash) {
    let f_Timer = null
    clearTimeout(f_Timer)
    f_Timer = setTimeout(() => {
      flash.style.display = 'none'
    }, 10000)
  }
  let leftcode = $(".leftcode");
  let overlay = $('#overlay')[0]
  let feedback_pop = $('#feedback_pop')[0]
  let feedback_select = $('#feedback_select')[0]
  let feedback_input = $('#feedback_input')[0]
  let feedback_textarea = $('#feedback_textarea')[0]
  let feedback_btn = $('#feedback_btn')
  let close = $('.close_success_img')
  let tel_rel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/

  
  leftcode.on('click', function () {
    feedback_input.value = ''
    feedback_select.selectedIndex = 0;
    feedback_textarea.value = ''
    overlay.style.display = "block";
    feedback_pop.style.display = "block";
  })
  close.on('click', function () {
    overlay.style.display = "none";
    feedback_pop.style.display = "none";
  })

  feedback_btn.on('click', () => {
    if (!feedback_select.value) {
      window.alert('请选择类型')
      return false
    }
    if (!feedback_input.value || /^\s*$/.test(feedback_input.value) || !tel_rel.test(feedback_input.value)) {
      window.alert('请输入正确手机号')
      return false
    }
    if (!(feedback_textarea.value.length > 15)) {
      window.alert('内容为空或未满15字')
      return false
    }
    return true
  })


  //产品介绍
  let introduce = document.querySelector('#introduce')
  if ((page == 'welcome' && action == 'search') || (page == 'products' && action == 'show') || (page == 'technologies' && action == 'detail' || action == 'service') || (page == 'articles' && action == 'show' || action == 'tag')||(page=='papers')) {
    introduce.style.display = 'block'
  } else {
    introduce.style.display = 'none'
  }

})


