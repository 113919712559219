import Swiper from 'swiper';
$(document).on('render:abouts', () => {
  if ($('body').attr('data-action') == 'show') {
    let vedio = document.getElementById('video')
    let iconImg = document.querySelectorAll('.chat_icon img')
    let header = document.querySelector('header')
    let md_head = document.querySelector('.head_top')

    let iconImg_url = []
    iconImg.forEach(v => {
      iconImg_url.push(v.src)
    })

    // 移动结构复制
    let md_s1 = document.querySelector('.md1')
    let md_s3 = document.querySelector('.md3')
    let md_s4=document.querySelector('.md4')
    let md_s5 = document.querySelector('.md5')
    let md_s6 = document.querySelector('.md6')
    let introduce = document.querySelector('.introduce')
    let structure = document.querySelector('.structure')
    let vision = document.querySelector('.vision')
    let activity = document.querySelector('.activity')
    let honour=document.querySelector('.about_honour')

    md_s1.innerHTML = introduce.outerHTML
    md_s3.innerHTML = structure.outerHTML
    md_s4.innerHTML = honour.outerHTML
    md_s5.innerHTML = vision.outerHTML
    md_s6.innerHTML = activity.outerHTML

    let cusitem = document.querySelectorAll('.cus_item')
    // 移动端导航背景
    window.addEventListener('scroll', () => {
      let aboutHeight = document.getElementById('about_md').scrollTop;
      if (aboutHeight >= 100) {
        md_head.style.background = '#fff'
      } else { //隐藏按钮正常
        md_head.style.background = ''
        md_head.style.boxShadow = ''
      }
      cusitem[cusitem.length - 1].addEventListener('click', () => {
        document.getElementById('about_md').scrollTop = 0;
      })
    }, true);


    // 一屏数据增加
    function addNumber(start, end, id, num) {
      var o = document.getElementById(id);
      var i = start;
      var Interval;
      Interval = setInterval(function () {
        i += num; // 设置每次增加的动态数字
        if (i > end) {
          clearInterval(Interval);
          o.innerHTML = end;
          i = 0;
        } else {
          o.innerHTML = i;
        }
      }, 40); // 数据跳转的速度控制
    }

    // 二屏pc
      let course_arr=[
        {
          year:'2024年',
          month:'7月',
          content:'蓝沙获批新专利《对被污染样品进行亲子鉴定的生物信息处理方法》'
        },
        {
          year:'2024年',
          month:'7月',
          content:'蓝沙满分通过全国肿瘤游离DNA基因突变高通量测序检测室间质评'
        },
        {
          year:'2024年',
          month:'4月',
          content:'蓝沙获批新专利《一种发夹结构的标签荧光探针和荧光检测方法》'
        },
        {
          year:'2024年',
          month:'2月',
          content:'蓝沙同时获批三项新专利《胎儿DNA浓度的评估方法及应用》、《以孕妇游离DNA评估胎儿DNA浓度的方法及应用》以及《以非生父和母亲DNA评估胎儿DNA浓度的方法及应用》'
        },
        {
          year:'2023年',
          month:'10月',
          content:'武汉蓝沙满分通过实体肿瘤体细胞突变高通量测序检测室间质评'
        },
        {
          year:'2023年',
          month:'10月',
          content:'上海蓝沙再次荣膺上海市“高新技术企业”认证'
        },
        {
          year:'2023年',
          month:'8月',
          content:'上海蓝沙入选上海市专精特新中小企业名单'
        },
        {
          year:'2023年',
          month:'7月',
          content:'武汉蓝沙医学检验实验室通过临床基因扩增检验实验室复审验收'
        },
        {
          year:'2022年',
          month:'12月',
          content:'武汉蓝沙满分通过新型冠状病毒奥密克戎变异株核酸检测室间质评'
        },
        {
          year:'2022年',
          month:'12月',
          content:'武汉蓝沙满分通过全国新型冠状病毒核酸检测室间质评'
        },
        {
          year:'2022年',
          month:'11月',
          content:'苏州蓝沙获得苏州市“高新技术企业”称号'
        },
        {
          year:'2021年',
          month:'12月',
          content:'武汉蓝沙获得武汉市“高新技术企业”称号'
        },
        {
          year:'2021年',
          month:'9月',
          content:'蓝沙护理站成立'
        },
        {
          year:'2020年',
          month:'11月',
          content:'上海蓝沙获得上海市“高新技术企业”称号'
        },
        {
          year:'2020年',
          month:'9月',
          content:'蓝沙联合苏州大学智能装备研究院，成立蓝沙生命科学智能化装备创新中心'
        },
        {
          year:'2020年',
          month:'7月',
          content:'蓝沙医学检验实验室审核获得生物安全实验室备案'
        },
        {
          year:'2020年',
          month:'5月',
          content:'蓝沙医学检验实验室获得临床基因扩增检验实验室技术审核合格证书'
        },
        {
          year:'2019年',
          month:'12月',
          content:'蓝沙生物入驻武汉，武汉蓝沙医学检验实验室开始运营'
        },
        {
          year:'2018年',
          month:'8月',
          content:'蓝沙生物入驻上海，上海蓝沙生物科技有限公司成立'
        },
        {
          year:'2017年',
          month:'11月',
          content:'苏州蓝沙生物科技有限公司（曾用名：苏因智启生物科技有限公司，2023年7月更名）成立'
        }
      ]


      
      let line_wrapper=document.querySelector('.line_wrapper')
      let course_str=course_arr.map(v=>{
        return `
         <div class="swiper-slide">
                      <span class="deve_year">${v.year}</span>
                      <div class="deve_content">
                        <span>${v.month}</span>
                        <p>${v.content}</p>
                      </div>
                    </div>
        `
      }).join('')
      
        line_wrapper.innerHTML=course_str
        var line_swiper = new Swiper(".line_swiper", {
          slidesPerView: 4,
          autoplay:{
            disableOnInteraction:false
          },
          loop:true,
          freeMode:true,
          observer: true,
          observeParents: true,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }
        });
        
        // 二屏移动
        let md_course_arr=[]
        course_arr.forEach(item => {
          let yearObj = md_course_arr.find(obj => obj.year === item.year);
          if (yearObj) {
            yearObj.arr.push({
              month: item.month,
              content: item.content
            });
          } else {
            md_course_arr.push({
              year: item.year,
              arr: [
                {
                  month: item.month,
                  content: item.content
                }
              ]
            });
          }
        });
        
    let md_development=document.querySelector('.md_development')
    let md_course_str=md_course_arr.map(v=>{
      return `
        <li>
          <span class='deve_year'>${v.year}</span>
            <div>
              ${v.arr.map(m=>{
                return ` <p>
                <span class='deve_month'>${m.month}</span>
                <span>${m.content}</span>
              </p>`
              }).join('')}
            </div>
        </li>
      `
    }).join('')
    md_development.innerHTML=md_course_str


    //三屏切换
    let structure_info = document.querySelectorAll('.structure_info')
    let structure_bg = document.querySelectorAll('.structure_bg')
    let switch_top = document.querySelectorAll('.switch_top')
    let switch_bom = document.querySelectorAll('.switch_bom')
    let s_top_img = document.querySelectorAll('.switch_top img')
    let s_bom_img = document.querySelectorAll('.switch_bom img')
    let info_list = document.querySelectorAll('.info_list')
    let stru_img = document.querySelectorAll('.stru_img')
    let pc_stru_img = document.querySelector('#about .stru_img')
    let md_stru_img = document.querySelector('#about_md .stru_img')
    let switch_md = document.querySelector('#about_md .switch')

    // md组织架构图片
    // md_stru_img.src = pc_stru_img.dataset.egUrl
    // let s_top_src = s_top_img[0].src
    // let s_bom_src = s_bom_img[0].src

    // switch_top.forEach((v, i) => {
    //   v.onmouseover = function () {
    //     info_list[i].style.display = 'block'
    //     stru_img[i].style.display = 'none'
    //     structure_bg[i].style.background = ""
    //     structure_info[i].style.padding = '0.7rem'
    //     s_top_img[i].src = s_top_src
    //     s_bom_img[i].src = s_bom_src
    //     switch_top[i].classList.add('big')
    //     switch_top[i].classList.replace('small', 'big')
    //     switch_bom[i].classList.add('small')
    //     switch_md.style.flexDirection = 'column'
    //   }
    // })
    // switch_bom.forEach((v, i) => {
    //   v.onmouseover = function () {
    //     info_list[i].style.display = 'none'
    //     stru_img[i].style.display = 'block'
    //     structure_bg[i].style.background = 'linear-gradient(45deg, #648af9 0%, #706bdf 100%)'
    //     structure_info[i].style.padding = '0'
    //     s_top_img[i].src = s_top_img[i].dataset.egUrl
    //     s_bom_img[i].src = s_bom_img[i].dataset.egUrl
    //     switch_bom[i].classList.replace('small', 'big')
    //     switch_bom[i].classList.add('big')
    //     switch_top[i].classList.add('small')
    //     switch_md.style.flexDirection = 'column-reverse'
    //   }
    // })

    // 四屏点击放大
    let honour_imgs = document.querySelectorAll('.honour_content img')
    let hon_vb = document.querySelector('.g_vb')
    let bigImg = document.querySelector('.bigImg')
    honour_imgs.forEach((v, i) => {
      v.onclick = function () {
        hon_vb.style.display = 'block'
        bigImg.src = v.dataset.egUrl
      }
    })
    hon_vb.onclick = function () {
      hon_vb.style.display = 'none'
    }
    bigImg.addEventListener('click', (e) => {
      e.stopPropagation()
    })

    // md
    let md_honouImgs=document.querySelectorAll('.md4 .honour_content img')
    md_honouImgs.forEach(v=>{
      v.src=v.dataset.codeUrl
    })

    // 六屏轮播
    var objImg = document.querySelectorAll('#about .objImg')
    let oneImg = document.querySelector('#about .oneImg')
    var objImg_md = document.querySelectorAll('.md6 .objImg')
    let oneImg_md = document.querySelector('.md6 .oneImg')

    function objSwiperFn(m, n) {
      let objImgArr = []
      m.forEach(v => {
        objImgArr.push(v.src)
      })
      let imgTimer = null
      let imgIndex = 0
      clearInterval(imgTimer)
      imgTimer = setInterval(() => {
        imgIndex++;
        imgIndex >= objImgArr.length && (imgIndex = 0)
        n.src = objImgArr[imgIndex];
      }, 2000)
    }
    // pc
    objSwiperFn(objImg, oneImg)
    // md
    objSwiperFn(objImg_md, oneImg_md)



    // 全屏
    $('#about').fullpage({
      slidesNavigation: true,
      scrollingSpeed: 1000,
      anchors: ['page1'],
      onLeave(index, nextIndex) {
        if (index.index == 0) {
          $('.section .common_title').eq(0).removeClass('animate__fadeInLeft')
          $('.section .common_title').eq(0).addClass('animate__fadeOut')
          $('.info').removeClass('animate__fadeIn')
          $('.g_va').removeClass('animate__fadeInRight')
          $('.introduce_data').removeClass('animate__fadeInUp')
          $('.info,.g_va,.introduce_data').addClass('animate__fadeOut')
        }
        if (index.index == 1) {
          $('.section .common_title').eq(1).removeClass('animate__fadeIn')
          $('.section .common_title').eq(1).addClass('animate__fadeOut')
          $('.deve_swiper').removeClass('animate__fadeIn')
          $('.deve_swiper').addClass('animate__fadeOut')
        }
        if (index.index == 2) {
          $('.structure_title,.structure_bg,.switch_bom').removeClass('animate__fadeInLeft')
          $('.structure_info').removeClass('animate__fadeInUp')
          $('.switch_top').removeClass('animate__fadeInRight')
          $('.info_list p').removeClass('animate__fadeInDown')
          $('.structure_title,.structure_bg,.structure_info,.switch_top,.switch_bom').addClass('animate__fadeOut')
          $('.info_list p').addClass('animate__fadeOut')
        }
        if (index.index == 3) {
          $('.honour_content,letters_title').addClass('animate__fadeOut')
          $('.letters_title').removeClass('animate__fadeInLeft')
          $('.honour_content').removeClass('animate__fadeIn')
        }
        if (index.index == 4) {
          $('.section .common_title').eq(3).removeClass('animate__fadeInLeft')
          $('.section .common_title').eq(3).addClass('animate__fadeOut')
          $('.vision_title p').removeClass('animate__fadeInDown')
          $('.vision_title p,.vision_list li').addClass('animate__fadeOut')
          $('.vision_list li').eq(0).removeClass('animate__fadeInUp')
          $('.vision_list li').eq(1).removeClass('animate__fadeInDown')
          $('.vision_list li').eq(2).removeClass('animate__fadeInUp')
          $('.vision_list li').eq(3).removeClass('animate__fadeInDown')
        }
        if (index.index == 5) {
          $('.section .common_title').eq(4).removeClass('animate__fadeInLeft')
          $('.section .common_title').eq(4).addClass('animate__fadeOut')
          $('.activity').removeClass('animate__fadeIn')

          $('.activity_swiper,.activity_list li,.activity').addClass('animate__fadeOut')
          $('.activity_swiper').removeClass('animate__fadeInLeft')
          $('.activity_list li').eq(0).removeClass('animate__fadeInDown')
          $('.activity_list li').eq(1).removeClass('animate__fadeInRight')
          $('.activity_list li').eq(2).removeClass('animate__fadeInLeft')
          $('.activity_list li').eq(3).removeClass('animate__fadeInUp')
        }
        // if (index.index == 6) {
        //   $('.section .common_title').eq(5).addClass('animate__fadeOut')
        //   $('.contact_title span,.contactus_call .call,.contactus_list li,.contactus_info').addClass('animate__fadeOut')
        // }
      },
      afterLoad(anchorLink, index) {
        if (index.index == 0) {
          $('.info').addClass('animate__fadeIn')
          $('.g_va').addClass('animate__fadeInRight')
          $('.introduce_data').addClass('animate__fadeInUp')
          $('.section .common_title').eq(0).removeClass('animate__fadeOut')
          $('.section .common_title').eq(0).addClass('animate__fadeInLeft')
          $('.info,.g_va,.introduce_data').removeClass('animate__fadeOut')

          // 数字变化
          // let dataTimer = null
          // clearTimeout(dataTimer)
          // dataTimer = setTimeout(() => {
          //   addNumber(0, 120000, 'n1', 2400);
          //   addNumber(0, 80000, 'n2', 1600);
          //   addNumber(0, 10000, 'n3', 200);
          //   addNumber(0, 2200, 'n4', 50);
          //   addNumber(0, 9, 'n5', 1);
          //   addNumber(0, 50, 'n6', 1);
          //   if (vedio.paused) {
          //     vedio.play()
          //   } else {
          //     vedio.pause()
          //   }
          // }, 70)
        }
        if (index.index == 1) {
          $('.section .common_title').eq(1).addClass('animate__fadeIn')
          $('.section .common_title').eq(1).removeClass('animate__fadeOut')
          $('.deve_swiper').addClass('animate__fadeIn')
          $('.deve_swiper').removeClass('animate__fadeOut')
          line_swiper.slideTo(4)
        }
        if (index.index == 2) {
          $('.structure_title,.structure_bg,.structure_info,.switch_top,.switch_bom').removeClass('animate__fadeOut')
          $('.structure_title,.structure_bg,.switch_bom').addClass('animate__fadeInLeft')
          $('.structure_info').addClass('animate__fadeInUp')
          $('.switch_top').addClass('animate__fadeInRight')
          $('.info_list p').addClass('animate__fadeInDown')
          $('.info_list p').removeClass('animate__fadeOut')
        }
        if (index.index == 3) {
          $('.honour_content,.letters_title').removeClass('animate__fadeOut')
          $('.letters_title').addClass('animate__fadeInLeft')
          $('.honour_content').addClass('animate__fadeIn')
        }
        if (index.index == 4) {
          $('.section .common_title').eq(3).addClass('animate__fadeInLeft')
          $('.section .common_title').eq(3).removeClass('animate__fadeOut')
          $('.vision_title p,.vision_list li').removeClass('animate__fadeOut')
          $('.vision_title p').addClass('animate__fadeInDown')
          $('.vision_list li').eq(0).addClass('animate__fadeInUp')
          $('.vision_list li').eq(1).addClass('animate__fadeInDown')
          $('.vision_list li').eq(2).addClass('animate__fadeInUp')
          $('.vision_list li').eq(3).addClass('animate__fadeInDown')
        }
        if (index.index == 5) {
          $('.section .common_title').eq(4).addClass('animate__fadeInLeft')
          $('.section .common_title').eq(4).removeClass('animate__fadeOut')
          $('.activity').addClass('animate__fadeIn')
          $('.activity_swiper').addClass('animate__fadeInLeft')
          $('.activity_swiper,.activity_list li,.activity').removeClass('animate__fadeOut')
          $('.activity_list li').eq(0).addClass('animate__fadeInDown')
          $('.activity_list li').eq(1).addClass('animate__fadeInRight')
          $('.activity_list li').eq(2).addClass('animate__fadeInLeft')
          $('.activity_list li').eq(3).addClass('animate__fadeInUp')
        }
        // if (index.index == 6) {
        //   $('.section .common_title').eq(5).removeClass('animate__fadeOut')
        //   $('.contact_title span,.contactus_call .call,.contactus_list li,.contactus_info').removeClass('animate__fadeOut')
        // }
        if (index.index == 7) {
          iconImg.forEach((v, i) => {
            v.src = v.dataset.codeUrl
            v.addEventListener('mouseout', () => {
              v.src = v.dataset.codeUrl
            })
          })
          header.style.backgroundColor = '#fff'
        } else {
          iconImg.forEach((v, i) => {
            v.src = iconImg_url[i]
            v.addEventListener('mouseout', () => {
              v.src = iconImg_url[i]
            })
          })
          header.style.backgroundColor = ''
        }
      }
    });
  }
  if ($('body').attr('data-action') == 'contact') {
    // 表单验证
    let radioInps = document.querySelectorAll('.pc_contactus .choose input')
    let nameInput = document.querySelectorAll('.nameInput')
    let telInput = document.querySelectorAll('.telInput')
    let submitBtn = document.querySelectorAll('.submitBtn')
    let r_select = $("#message_category option:selected")

    let rel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    let r_Arr = []
    let flash = document.querySelector('.flash')
    if (flash) {
      let f_Timer = null
      clearTimeout(f_Timer)
      f_Timer = setTimeout(() => {
        flash.style.display = 'none'
      }, 10000)
    }

    submitBtn.forEach((item, index) => {
      item.onclick = function () {
        if (index == 0) {
          radioInps.forEach(v => {
            r_Arr.push(v.checked)
          })
          if (!r_Arr.includes(true)) {
            alert('请选择咨询项目')
            return false;
          }
        }
        if (index == 1) {
          if (r_select.val() == '') {
            alert('请选择咨询项目')
            return false;
          }
        }

        if (nameInput[index].value.trim() == '') {
          alert("请输入姓名");
          return false;
        }
        if (telInput[index].value.trim() == '' || !rel.test(telInput[index].value)) {
          alert("请输入正确的手机号");
          return false;
        }
        if ($(".textInput").eq(index).val().trim() == '') {
          alert("请输入咨询内容");
          return false;
        }
        return true;
      }
    })

    // 隐私政策
    let privacy_vb = document.querySelector('.g_vb')
    let privacy_right = document.querySelector('.g_right')
    let privacy_btn = document.querySelectorAll('.privacy_btn')
    privacy_btn.forEach(v => {
      v.onclick = function () {
        privacy_vb.style.display = 'flex'
        document.body.style.overflow = 'hidden'; //禁止滚动
      }
    })
    privacy_right.onclick = function () {
      privacy_vb.style.display = 'none'
      document.body.style.overflow = ''; //出现滚动条
    }
  }
  if($('body').attr('data-action')=='cooperate'){
    let f_radio=document.querySelector('.f_radio')
    let radioInps = document.querySelectorAll('.radio input')
    let nameInput = document.querySelector('.nameInput')
    let telInput = document.querySelector('.telInput')
    let submitBtn = document.querySelector('.submitBtn')
    let r_select = $("#message_category option:selected")

    let rel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    let r_Arr = []
    let flash = document.querySelector('.flash')
    if (flash) {
      let f_Timer = null
      clearTimeout(f_Timer)
      f_Timer = setTimeout(() => {
        flash.style.display = 'none'
      }, 10000)
    }

    submitBtn.onclick=function(){
      
      if (nameInput.value.trim() == '') {
        alert("请输入您的称呼");
        return false;
      }
      if (telInput.value.trim() == '' || !rel.test(telInput.value)) {
        alert("请输入正确的手机号");
        return false;
      }
      if(getComputedStyle(f_radio).display=='block'){
        radioInps.forEach(v => {
          r_Arr.push(v.checked)
        })
        if (!r_Arr.includes(true)) {
          alert('请选择代理业务')
          return false;
        }
      }

      if(getComputedStyle(f_radio).display=='none'){
        if (r_select.val() == '') {
          alert('请选择代理业务')
          return false;
        }
      }
      if ($(".textInput").val().trim() == '') {
        alert("请输入代理区域");
        return false;
      }
      return true;
    }
  }
  if($('body').attr('data-action')=='project_message'){


    // swiper 配置项
    const mySwiper = new Swiper('.swiper', {
      direction: "vertical",
      slidesPerView: 15,
      initialSlide: 49,
      loop: false, // 循环
      // 自动播放配置
      autoplay: {
        reverseDirection: true,
        disableOnInteraction: false,
        delay: 2000 // 间隔时间
      }
    })
  }
});