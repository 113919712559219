$(document).on('render:requisitions', () => {
  if ($('body').attr('data-action') == 'index') {
    let choose_radio = document.querySelectorAll('.radio input')
    let requisitions_id = document.querySelector('.requisitions_id')
    let requisitions_tel = document.querySelector('.requisitions_tel')
    let state = 'code'
    choose_radio.forEach(v => {
      v.onclick = function () {
        state = v.value
        if (v.value == 'code') {
          requisitions_id.style.display = 'block'
          requisitions_tel.style.display = 'none'
        } else {
          requisitions_id.style.display = 'none'
          requisitions_tel.style.display = 'block'
        }
      }
    })
    // 搜索验证
    let search_btn = document.querySelector('.search_btn')
    let id_input = document.querySelector('.id_input')
    let tel_input = document.querySelector('.tel_input')
    let code_input = document.querySelector('.code_input')
    let tel_rel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/

    search_btn.onclick = function () {
      if (state == 'code') {
        if (!id_input.value || /^\s*$/.test(id_input.value)) {
          alert('唯一编码不能为空！')
          return false
        }
      } else {
        if (!tel_input.value || /^\s*$/.test(tel_input.value) || !tel_rel.test(tel_input.value)) {
          window.alert('手机号格式错误')
          return false
        }
        if (!code_input.value || /^\s*$/.test(code_input.value)) {
          window.alert('验证码不能为空')
          return false
        }
      }
      return true
    }

    // 验证码倒计时
    let code_btn = document.querySelector('.code_btn')
    let code_bool = true

    code_btn.onclick = function () {
      if (!tel_input.value || /^\s*$/.test(tel_input.value) || !tel_rel.test(tel_input.value)) {
        return window.alert('手机号格式错误')
      }
      let seconds = 60
      if (code_bool) {
        code_btn.innerHTML = seconds
        let intervalId = setInterval(() => {
          seconds--
          code_btn.innerHTML = seconds + '后重新获取'
          code_bool = false
          if (seconds == 0) {
            clearInterval(intervalId);
            code_btn.innerHTML = '重新获取'
            code_bool = true
          }
        }, 1000)
      }
    }


  }
})