import Swiper from 'swiper';
$(document).on('render:welcome', () => {
  const action = document.body.dataset.action

  $('body[data-page="welcome"][data-action="search"]').on('click', 'nav.pagination a', (event) => {
    event.preventDefault()
    const target = event.target
    const form = $(target).closest('form')
    form.attr('action', event.target.href)
    form.submit()
  })

  if ($('body').attr('data-action') == 'index') {
    let logo_b = document.querySelector('.logo_b')
    let innew = document.querySelector('.innew')
    let input_bg = document.querySelectorAll('.search_inp input')[1]
    let nav_a = document.querySelectorAll('header nav a')
    let search = document.querySelector('header .search')
    let inp_img = document.querySelector('header .search_inp img')
    let iconImg = document.querySelectorAll('.chat_icon img')
    let s_result = document.querySelector('.head_top .s_result')
    let nav_two = document.querySelectorAll('.nav_two')

    let md_head = document.querySelector('.head_top')

    //图片变化
    let logo_b_url = logo_b.src
    let inp_img_url = inp_img.src
    let iconImg_url = []
    iconImg.forEach(v => {
      iconImg_url.push(v.src)
    })

    // 移动端
    let md_s2 = document.querySelector('.md2')
    let md_s3 = document.querySelector('.md3')
    let md_s4 = document.querySelector('.md4')
    let md_s5 = document.querySelector('.md5')
    let home_type = document.querySelector('.section .home_type')
    let home_abouts = document.querySelector('.section .home_aboutus')
    let home_news = document.querySelector('.section .home_news')
    let home_honour=document.querySelector('.section .home_honour')
    md_s2.innerHTML = home_type.outerHTML
    md_s3.innerHTML = home_abouts.outerHTML
    md_s4.innerHTML = home_news.outerHTML
    md_s5.innerHTML = home_honour.outerHTML

    let cusitem = document.querySelectorAll('.cus_item')
    // 移动滚动导航
    window.addEventListener('scroll', () => {
      let homeHeight = document.getElementById('home_md').scrollTop;
      if (homeHeight >= 100) {
        md_head.style.background = '#fff'
      } else {
        md_head.style.background = ''
        md_head.style.boxShadow = ''
      }
      cusitem[cusitem.length - 1].addEventListener('click', () => {
        document.getElementById('home_md').scrollTop = 0;
      })
    }, true);


    // pc首页轮播
    var pcbannerSwiper = new Swiper(".pc_banner", {
      loop: true,
      autoplay: {
        disableOnInteraction: false,
        delay: 3000,
      },
      freeMode:true,
      pagination: {
        el: '.swiper-pagination',
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }
    });
    // md首页轮播
    var mdbannerSwiper = new Swiper(".md_banner", {
      loop: true,
      autoplay: {
        disableOnInteraction: false,
        delay: 3000,
      },
      pagination: {
        el: '.swiper-pagination',
      }
    });

    // md尾部轮播
    var mySwiper = new Swiper(".foot_swiper", {
      direction: 'horizontal', // 滚动方向
      loop: true, // 循环
      autoplay: {
        disableOnInteraction: false,
        delay: 3000,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }
    })

    // 二屏触碰显示
    let type_item = document.querySelectorAll('.type-item')
    let item_list = document.querySelectorAll('.itemlist')
    let item_img = document.querySelectorAll('.itemlist img')
    let type_bg = document.querySelectorAll('.type_bg')
    type_item.forEach((v, i) => {
      v.addEventListener('mouseover', () => {
        item_img[i].style.display = 'block'
        item_list[i].classList.add('load1')
        item_list[i].classList.remove('load2')
        type_bg[i].className = 'type_bg1'
      })
      v.addEventListener('mouseout', () => {
        item_img[i].style.display = 'none'
        item_list[i].classList.add('load2')
        item_list[i].classList.remove('load1')
        type_bg[i].className = ''
      })
    })

    // 三屏数据增加
    function addNumber(start, end, id, num) {
      var o = document.getElementById(id);
      var i = start;
      var Interval;
      Interval = setInterval(function () {
        i += num; // 设置每次增加的动态数字
        if (i > end) {
          clearInterval(Interval);
          o.innerHTML = end;
          i = 0;
        } else {
          o.innerHTML = i;
        }
      }, 40); // 数据跳转的速度控制
    }

    // 四屏新闻轮播
    let pc_lists_item = document.querySelectorAll('#home .lists_item')
    let pc_items = document.querySelectorAll("#home .news_left_img .item"); //图片
    let pc_points = document.querySelectorAll("#home .point") //点
    let pc_cons = document.querySelectorAll('#home .news_left_swiper .con')
    let pc_all = document.querySelector("#home .news_left")

    let md_lists_item = document.querySelectorAll('#home_md .lists_item')
    let md_items = document.querySelectorAll("#home_md .news_left_img .item"); //图片
    let md_points = document.querySelectorAll("#home_md .point") //点
    let md_cons = document.querySelectorAll('#home_md .news_left_swiper .con')
    let md_all = document.querySelector("#home_md .news_left")

    function newsSwiperFn(lists_item, items, points, cons, all) {
      let index = 0;
      let time = 0; //定时器跳转参数初始化
      var clearActive = function () {
        for (let i = 0; i < items.length; i++) {
          items[i].className = 'item';
        }
        for (let j = 0; j < points.length; j++) {
          points[j].className = 'point';
        }
        for (let k = 0; k < cons.length; k++) {
          cons[k].className = 'con';
        }
        for (let l = 0; l < lists_item.length; l++) {
          lists_item[l].className = 'lists_item';
        }
      }
      cons[0].className = 'con active'
      lists_item[0].className = 'lists_item active'

      //改变active方法
      var goIndex = function () {
        clearActive();
        items[index].className = 'item active';
        points[index].className = 'point active'
        cons[index].className = 'con active'
        lists_item[index].className = 'lists_item active'

      }

      //自动轮播
      var goRight = function () {
        if (index < 3) {
          index++;
        } else {
          index = 0;
        }
        goIndex();
      }

      for (let i = 0; i < points.length; i++) {
        points[i].addEventListener('click', function () {
          var pointIndex = this.getAttribute('data-index')
          index = pointIndex;
          goIndex();
          time = 0; //计时器跳转清零
        })
      }
      //计时器
      var timer;

      function play() {
        timer = setInterval(() => {
          time++;
          if (time == 20) {
            goRight();
            time = 0;
          }
        }, 150)
      }
      play();
      //移入清除计时器
      all.onmousemove = function () {
        clearInterval(timer)
      }
      //移出启动计时器
      all.onmouseleave = function () {
        play();
      }
    }

    newsSwiperFn(pc_lists_item, pc_items, pc_points, pc_cons, pc_all)
    newsSwiperFn(md_lists_item, md_items, md_points, md_cons, md_all)

    // pc5屏放大
    let honour_imgs = document.querySelectorAll('.honour_content img')
    let hon_vb = document.querySelector('.g_vb')
    let bigImg = document.querySelector('.bigImg')
    honour_imgs.forEach((v, i) => {
      v.onclick = function () {
        hon_vb.style.display = 'block'
        bigImg.src = v.dataset.egUrl
      }
    })
    hon_vb.onclick = function () {
      hon_vb.style.display = 'none'
    }
    bigImg.addEventListener('click', (e) => {
      e.stopPropagation()
    })

    // pc六屏轮播
    var footSwiper = new Swiper(".foot_content", {
      slidesPerView: 6,
      slidesPerGroup: 1,
      loop: true,
      freeMode: true,
      speed: 3000,
      autoplay: {
        disableOnInteraction: false,
        delay: 0 //1秒切换一次
      }
    });

    // 全屏滚动
    $('#home').fullpage({
      scrollingSpeed: 1000,
      loopHorizontal: true,
      anchors: ['page1'],
      // // 离开页面
      onLeave(index, nextIndex) {

        if (index.index == 2) {
          $('.about').removeClass('animate__fadeInLeft')
          $('.about_data').removeClass('animate__fadeInUp')
          $('.about,.about_data').addClass('animate__fadeOut')
        }
        if (index.index == 3) {
          innew.style.width = '0rem'
          $('.title-img,.news_right_bg').removeClass('animate__fadeIn')
          $('.news_left_swiper').removeClass('animate__fadeInUp')
          $('.news_left_img').removeClass('animate__fadeInRight')
          $('.lists_items').removeClass('animate__fadeInDown')
          $('.pointList').removeClass('animate__fadeInUp')
          $('.title-img,.news_right_bg,.news_left_swiper,.news_left_img,.lists_items,.pointList').addClass('animate__fadeOut')
        }
        if (index.index == 4) {
          $('.honour_content, .honour_title img').addClass('animate__fadeOut')
          $('.honour_title img').removeClass('animate__fadeInLeft')
          $('.honour_content').removeClass('animate__fadeIn')
        }
        if (index.index == 5) {
          $('.foot_title img').addClass('animate__fadeOut')
          $('.foot_title img').removeClass('animate__fadeIn')
        }
      },
      afterLoad(anchorLink, index) {
        if (index.index == 2) {
          $('.about,.about_data').removeClass('animate__fadeOut')
          $('.about').addClass('animate__fadeInLeft')
          $('.about_data').addClass('animate__fadeInUp')

          // let timerData = null
          // // 数字变化
          // clearTimeout(timerData)
          // timerData = setTimeout(() => {
          //   addNumber(0, 120000, 'n1', 2400);
          //   addNumber(0, 80000, 'n2', 1600);
          //   addNumber(0, 10000, 'n3', 200);
          //   addNumber(0, 2200, 'n4', 50);
          //   addNumber(0, 9, 'n5', 1);
          //   addNumber(0, 50, 'n6', 1);
          // }, 500)
        }
        if (index.index == 3) {
          let inTimer = null
          clearTimeout(inTimer)
          inTimer = setTimeout(() => {
            innew.style.width = '1.6rem'
          }, 900)
          $('.title-img,.news_right_bg').addClass('animate__fadeIn')
          $('.news_left_swiper').addClass('animate__fadeInUp')
          $('.news_left_img').addClass('animate__fadeInRight')
          $('.lists_items').addClass('animate__fadeInDown')
          $('.lists_items').eq(0).addClass('delay-5')
          $('.lists_items').eq(1).addClass('delay-6')
          $('.lists_items').eq(2).addClass('delay-7')
          $('.lists_items').eq(3).addClass('delay-8')
          $('.pointList').addClass('animate__fadeInUp')
          $('.title-img,.news_right_bg,.news_left_swiper,.news_left_img,.lists_items,.pointList').removeClass('animate__fadeOut')
        }
        if (index.index == 4) {
          $('.honour_content,.honour_title img').removeClass('animate__fadeOut')
          $('.honour_title img').addClass('animate__fadeInLeft')
          $('.honour_content').addClass('animate__fadeIn')

        }
        if (index.index == 5) {
          $('.foot_title img').removeClass('animate__fadeOut')
          $('.foot_title img').addClass('animate__fadeIn')
        }
        if (index.index == 1 || index.index == 2) {
          nav_a.forEach(v => {
            v.classList.add('write')
            nav_a[5].style.color = '#fff'
            nav_a[5].classList.add('language1')
          })
          logo_b.src = logo_b.dataset.egUrl
          input_bg.classList.add('input-f')
          input_bg.classList.remove('input-w')

          s_result.style.color = '#001e62'

          // 搜索框图标颜色
          inp_img.src = inp_img.dataset.egUrl
          search.addEventListener('mouseover', () => {
            inp_img.src = inp_img_url
          })
          search.addEventListener('mouseout', () => {
            inp_img.src = inp_img.dataset.egUrl
          })

          iconImg.forEach((v, i) => {
            v.src = v.dataset.codeUrl
            v.addEventListener('mouseout', () => {
              v.src = v.dataset.codeUrl
            })
          })
        } else if (index.index == 5) {
          nav_a[5].style.color = '#001e62'
          nav_a[5].classList.replace('language1', 'language')

          iconImg.forEach((v, i) => {
            v.src = v.dataset.codeUrl
            v.addEventListener('mouseout', () => {
              v.src = v.dataset.codeUrl
            })
          })
        } else {
          nav_a.forEach(v => {
            v.classList.remove('write')
          })
          nav_a[5].style.color = '#001e62'
          nav_a[5].classList.replace('language1', 'language')
          logo_b.src = logo_b_url
          input_bg.classList.remove('input-f')
          input_bg.classList.add('input-w')

          s_result.style.color = '#fff'

          // 搜索框图标颜色
          inp_img.src = inp_img_url
          search.addEventListener('mouseover', () => {
            inp_img.src = inp_img.dataset.egUrl
          })
          search.addEventListener('mouseout', () => {
            inp_img.src = inp_img_url
          })

          iconImg.forEach((v, i) => {
            v.src = iconImg_url[i]
            v.addEventListener('mouseout', () => {
              v.src = iconImg_url[i]
            })
          })
        }

        if (index.index == 0 || index.index == 1 || index.index == 2) {
          nav_two.forEach(v => {
            v.classList.add('nav_bg1')
            v.classList.remove('nav_bg2')
          })
        } else {
          nav_two.forEach(v => {
            v.classList.add('nav_bg2')
            v.classList.remove('nav_bg1')
          })
        }
      }
    });
    setInterval(function () {
      $.fn.fullpage.moveSlideRight();
    }, 3000);
  }
  if ($('body').attr('data-action') == 'search') {
    let hot_box=document.querySelector('.hot_box')
      hot_box.onmousedown=function(e){
        e.preventDefault()
        return false;
      }
  }
})