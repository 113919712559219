$(document).on('render:jobs', () => {
  if($('body').attr('data-action')=='show'){
    document.querySelector('.back_btn').style.display='flex'

  }else{
    let chooseLi=document.querySelectorAll('.drop_choose p')
    let chooseImg=document.querySelector('.select img')
    let chooseAdd=document.querySelector('.select_add')
    let chooseLists=document.querySelector('.select_choose')
    let addName=document.querySelector('.address_name')
    let addSpans=document.querySelectorAll('.select_choose span')
    chooseLi.forEach((item,key)=>{
      item.onclick=function(){
        console.log('12313');
        
        chooseLi.forEach((i,k)=>{
          i.className=''
        })
        item.className='active'
      }
    })
    chooseImg.onclick=function(){
        chooseLists.style.display='flex'
      chooseAdd.style.height='auto'
      console.log('2324');
      
      addSpans.forEach(v=>{
        v.onclick=function(){
          addName.innerText=v.innerText
          chooseLists.style.display='none'
        }
      })
    }
  
  
  }
  
})
