export const cityData= [
  {
    title: "热门城市",
    lists: [
      { city: "北京市", pinyin: "beijing" },
      { city: "武汉市", pinyin: "wuhan" },
      { city: "上海市", pinyin: "shanghai" },
      { city: "广州市", pinyin: "guangzhou" },
      { city: "深圳市", pinyin: "Shenzhen" },
      { city: "重庆市", pinyin: "chongqing" },
      { city: "杭州市", pinyin: "hangzhou" },
      { city: "苏州市", pinyin: "suzhou" },
      { city: "成都市", pinyin: "chengdu" },
      { city: "天津市", pinyin: "tianjin" },
    ],
  },
  {
    title: "省",
    lists: [
      { city: "河北省", pinyin: "hebei" },
      { city: "山西省", pinyin: "shanxi" },
      { city: "辽宁省", pinyin: "liaoning" },
      { city: "吉林省", pinyin: "jilin" },
      { city: "安徽省", pinyin: "anhui" },
      { city: "福建省", pinyin: "fujian" },
      { city: "江西省", pinyin: "jiangxi" },
      { city: "江苏省", pinyin: "jiangsu" },
      { city: "山东省", pinyin: "shandong" },
      { city: "河南省", pinyin: "henan" },
      { city: "湖北省", pinyin: "hubei" },
      { city: "湖南省", pinyin: "hunan" },
      { city: "广东省", pinyin: "guangdong" },
      { city: "海南省", pinyin: "hainan" },
      { city: "四川省", pinyin: "sichuan" },
      { city: "贵州省", pinyin: "guizhou" },
      { city: "云南省", pinyin: "yunnan" },
      { city: "陕西省", pinyin: "shanxi" },
      { city: "甘肃省", pinyin: "gansu" },
      { city: "青海省", pinyin: "qinghai" },
      { city: "浙江省", pinyin: "zhejiang" },
      { city: "台湾省", pinyin: "taiwan" },
      { city: "黑龙江省", pinyin: "heilongjiang" },
    ],
  },
  {
    title: "自治区",
    lists: [
      { city: "内蒙古自治区", pinyin: "neimenggu" },
      { city: "西藏自治区", pinyin: "xizang" },
      { city: "广西壮族自治区", pinyin: "guangxi" },
      { city: "宁夏回族自治区", pinyin: "ningxia" },
      { city: "新疆维吾尔自治区", pinyin: "xinjiang" },
    ],
  },
  {
    title: "直辖市",
    lists: [
      { city: "北京市", pinyin: "beijing" },
      { city: "天津市", pinyin: "tianjin" },
      { city: "上海市", pinyin: "shanghai" },
      { city: "重庆市", pinyin: "chongqing" },
    ],
  },
  {
    title: "特别行政区",
    lists: [
      { city: "香港特别行政区", pinyin: "xianggang" },
      { city: "澳门特别行政区", pinyin: "aomen" },
    ],
  },
    {
        "title": "A",
        "lists": [
            {
                "city": "阿坝州",
                "pinyin": "abazhou"
            },
            {
                "city": "阿城区",
                "pinyin": "achengqu"
            },
            {
                "city": "阿城市",
                "pinyin": "achengshi"
            },
            {
                "city": "阿尔山市",
                "pinyin": "aershanshi"
            },
            {
                "city": "阿克苏市",
                "pinyin": "akesushi"
            },
            {
                "city": "阿拉尔市",
                "pinyin": "alaershi"
            },
            {
                "city": "阿勒泰市",
                "pinyin": "aletaishi"
            },
            {
                "city": "安达市",
                "pinyin": "andashi"
            },
            {
                "city": "安国市",
                "pinyin": "anguoshi"
            },
            {
                "city": "安康",
                "pinyin": "ankang"
            },
            {
                "city": "安陆市",
                "pinyin": "anlushi"
            },
            {
                "city": "安宁区",
                "pinyin": "anningqu"
            },
            {
                "city": "安宁市",
                "pinyin": "anningshi"
            },
            {
                "city": "安庆市",
                "pinyin": "anqingshi"
            },
            {
                "city": "安顺市",
                "pinyin": "anshunshi"
            },
            {
                "city": "安阳市",
                "pinyin": "anyangshi"
            },
            {
                "city": "安义县",
                "pinyin": "anyixian"
            },
            {
                "city": "鞍山市",
                "pinyin": "anshanshi"
            }
        ]
    },
    {
        "title": "B",
        "lists": [
            {
                "city": "巴南区",
                "pinyin": "bananqu"
            },
            {
                "city": "巴彦淖尔市",
                "pinyin": "bayannaoershi"
            },
            {
                "city": "巴彦县",
                "pinyin": "bayanxian"
            },
            {
                "city": "巴中市",
                "pinyin": "bazhongshi"
            },
            {
                "city": "霸州市",
                "pinyin": "bazhoushi"
            },
            {
                "city": "灞桥区",
                "pinyin": "baqiaoqu"
            },
            {
                "city": "白城市",
                "pinyin": "baichengshi"
            },
            {
                "city": "白山市",
                "pinyin": "baishanshi"
            },
            {
                "city": "白下区",
                "pinyin": "baixiaqu"
            },
            {
                "city": "白银市",
                "pinyin": "baiyinshi"
            },
            {
                "city": "白云区",
                "pinyin": "baiyunqu"
            },
            {
                "city": "百色市",
                "pinyin": "baiseshi"
            },
            {
                "city": "蚌埠市",
                "pinyin": "bengbushi"
            },
            {
                "city": "包河区",
                "pinyin": "baohequ"
            },
            {
                "city": "包头市",
                "pinyin": "baotoushi"
            },
            {
                "city": "宝坻区",
                "pinyin": "baodiqu"
            },
            {
                "city": "宝鸡",
                "pinyin": "baoji"
            },
            {
                "city": "宝山区",
                "pinyin": "baoshanqu"
            },
            {
                "city": "保定市",
                "pinyin": "baodingshi"
            },
            {
                "city": "保山市",
                "pinyin": "baoshanshi"
            },
            {
                "city": "碑林区",
                "pinyin": "beilinqu"
            },
            {
                "city": "北安市",
                "pinyin": "beianshi"
            },
            {
                "city": "北碚区",
                "pinyin": "beibeiqu"
            },
            {
                "city": "北辰区",
                "pinyin": "beichenqu"
            },
            {
                "city": "北海市",
                "pinyin": "beihaishi"
            },
            {
                "city": "北流市",
                "pinyin": "beiliushi"
            },
            {
                "city": "北宁市",
                "pinyin": "beiningshi"
            },
            {
                "city": "北票市",
                "pinyin": "beipiaoshi"
            },
            {
                "city": "北投区",
                "pinyin": "beitouqu"
            },
            {
                "city": "本溪市",
                "pinyin": "benxishi"
            },
            {
                "city": "毕节市",
                "pinyin": "bijieshi"
            },
            {
                "city": "璧山区",
                "pinyin": "bishanqu"
            },
            {
                "city": "宾县",
                "pinyin": "binxian"
            },
            {
                "city": "宾阳县",
                "pinyin": "binyangxian"
            },
            {
                "city": "滨海新区",
                "pinyin": "binhaixinqu"
            },
            {
                "city": "滨江区",
                "pinyin": "binjiangqu"
            },
            {
                "city": "滨州市",
                "pinyin": "binzhoushi"
            },
            {
                "city": "亳州市",
                "pinyin": "bozhoushi"
            },
            {
                "city": "泊头市",
                "pinyin": "botoushi"
            }
        ]
    },
    {
        "title": "C",
        "lists": [
            {
                "city": "蔡甸区",
                "pinyin": "caidianqu"
            },
            {
                "city": "仓山区",
                "pinyin": "cangshanqu"
            },
            {
                "city": "沧州市",
                "pinyin": "cangzhoushi"
            },
            {
                "city": "岑溪市",
                "pinyin": "cenxishi"
            },
            {
                "city": "昌吉市",
                "pinyin": "changjishi"
            },
            {
                "city": "昌平区",
                "pinyin": "changpingqu"
            },
            {
                "city": "常德市",
                "pinyin": "changdeshi"
            },
            {
                "city": "常宁市",
                "pinyin": "changningshi"
            },
            {
                "city": "常熟市",
                "pinyin": "changshushi"
            },
            {
                "city": "常州市",
                "pinyin": "changzhoushi"
            },
            {
                "city": "巢湖市",
                "pinyin": "chaohushi"
            },
            {
                "city": "朝阳区",
                "pinyin": "chaoyangqu"
            },
            {
                "city": "朝阳市",
                "pinyin": "chaoyangshi"
            },
            {
                "city": "潮州市",
                "pinyin": "chaozhoushi"
            },
            {
                "city": "郴州市",
                "pinyin": "chenzhoushi"
            },
            {
                "city": "成都市",
                "pinyin": "chengdoushi"
            },
            {
                "city": "成华区",
                "pinyin": "chenghuaqu"
            },
            {
                "city": "呈贡区",
                "pinyin": "chenggongqu"
            },
            {
                "city": "承德市",
                "pinyin": "chengdeshi"
            },
            {
                "city": "城北区",
                "pinyin": "chengbeiqu"
            },
            {
                "city": "城关区",
                "pinyin": "chengguanqu"
            },
            {
                "city": "城口县",
                "pinyin": "chengkouxian"
            },
            {
                "city": "城西区",
                "pinyin": "chengxiqu"
            },
            {
                "city": "城中区",
                "pinyin": "chengzhongqu"
            },
            {
                "city": "池州市",
                "pinyin": "chizhoushi"
            },
            {
                "city": "赤壁市",
                "pinyin": "chibishi"
            },
            {
                "city": "赤峰市",
                "pinyin": "chifengshi"
            },
            {
                "city": "崇明区",
                "pinyin": "chongmingqu"
            },
            {
                "city": "崇文区",
                "pinyin": "chongwenqu"
            },
            {
                "city": "崇州市",
                "pinyin": "chongzhoushi"
            },
            {
                "city": "崇左市",
                "pinyin": "chongzuoshi"
            },
            {
                "city": "滁州市",
                "pinyin": "chuzhoushi"
            },
            {
                "city": "楚雄市",
                "pinyin": "chuxiongshi"
            },
            {
                "city": "淳安县",
                "pinyin": "chunanxian"
            },
            {
                "city": "慈溪市",
                "pinyin": "cixishi"
            },
            {
                "city": "从化区",
                "pinyin": "conghuaqu"
            },
            {
                "city": "长安区",
                "pinyin": "changanqu"
            },
            {
                "city": "长春市",
                "pinyin": "changchunshi"
            },
            {
                "city": "长丰县",
                "pinyin": "changfengxian"
            },
            {
                "city": "长葛市",
                "pinyin": "changgeshi"
            },
            {
                "city": "长乐区",
                "pinyin": "changlequ"
            },
            {
                "city": "长乐市",
                "pinyin": "changleshi"
            },
            {
                "city": "长宁区",
                "pinyin": "changningqu"
            },
            {
                "city": "长清区",
                "pinyin": "changqingqu"
            },
            {
                "city": "长沙市",
                "pinyin": "changshashi"
            },
            {
                "city": "长沙县",
                "pinyin": "changshaxian"
            },
            {
                "city": "长寿区",
                "pinyin": "changshouqu"
            },
            {
                "city": "长治市",
                "pinyin": "changzhishi"
            }
        ]
    },
    {
        "title": "D",
        "lists": [
            {
                "city": "达坂城区",
                "pinyin": "dabanchengqu"
            },
            {
                "city": "达州市",
                "pinyin": "dazhoushi"
            },
            {
                "city": "大安区",
                "pinyin": "daanqu"
            },
            {
                "city": "大安市",
                "pinyin": "daanshi"
            },
            {
                "city": "大东区",
                "pinyin": "dadongqu"
            },
            {
                "city": "大渡口区",
                "pinyin": "dadukouqu"
            },
            {
                "city": "大理市",
                "pinyin": "dalishi"
            },
            {
                "city": "大连市",
                "pinyin": "dalianshi"
            },
            {
                "city": "大庆市",
                "pinyin": "daqingshi"
            },
            {
                "city": "大石桥市",
                "pinyin": "dashiqiaoshi"
            },
            {
                "city": "大通回族土族自治县",
                "pinyin": "datonghuizutuzuzizhixian"
            },
            {
                "city": "大同区",
                "pinyin": "datongqu"
            },
            {
                "city": "大同市",
                "pinyin": "datongshi"
            },
            {
                "city": "大兴区",
                "pinyin": "daxingqu"
            },
            {
                "city": "大冶市",
                "pinyin": "dayeshi"
            },
            {
                "city": "大邑县",
                "pinyin": "dayixian"
            },
            {
                "city": "大足区",
                "pinyin": "dazuqu"
            },
            {
                "city": "丹东市",
                "pinyin": "dandongshi"
            },
            {
                "city": "丹江口市",
                "pinyin": "danjiangkoushi"
            },
            {
                "city": "丹阳市",
                "pinyin": "danyangshi"
            },
            {
                "city": "儋州市",
                "pinyin": "danzhoushi"
            },
            {
                "city": "当阳市",
                "pinyin": "dangyangshi"
            },
            {
                "city": "道里区",
                "pinyin": "daoliqu"
            },
            {
                "city": "道外区",
                "pinyin": "daowaiqu"
            },
            {
                "city": "德惠市",
                "pinyin": "dehuishi"
            },
            {
                "city": "德兴市",
                "pinyin": "dexingshi"
            },
            {
                "city": "德州市",
                "pinyin": "dezhoushi"
            },
            {
                "city": "灯塔市",
                "pinyin": "dengtashi"
            },
            {
                "city": "登封市",
                "pinyin": "dengfengshi"
            },
            {
                "city": "邓州市",
                "pinyin": "dengzhoushi"
            },
            {
                "city": "垫江县",
                "pinyin": "dianjiangxian"
            },
            {
                "city": "定西市",
                "pinyin": "dingxishi"
            },
            {
                "city": "定州市",
                "pinyin": "dingzhoushi"
            },
            {
                "city": "东城区",
                "pinyin": "dongchengqu"
            },
            {
                "city": "东川区",
                "pinyin": "dongchuanqu"
            },
            {
                "city": "东港市",
                "pinyin": "donggangshi"
            },
            {
                "city": "东莞市",
                "pinyin": "dongwanshi"
            },
            {
                "city": "东湖区",
                "pinyin": "donghuqu"
            },
            {
                "city": "东丽区",
                "pinyin": "dongliqu"
            },
            {
                "city": "东台市",
                "pinyin": "dongtaishi"
            },
            {
                "city": "东西湖区",
                "pinyin": "dongxihuqu"
            },
            {
                "city": "东乡县",
                "pinyin": "dongxiangxian"
            },
            {
                "city": "东兴市",
                "pinyin": "dongxingshi"
            },
            {
                "city": "东阳市",
                "pinyin": "dongyangshi"
            },
            {
                "city": "东营市",
                "pinyin": "dongyingshi"
            },
            {
                "city": "都江堰市",
                "pinyin": "doujiangyanshi"
            },
            {
                "city": "敦化市",
                "pinyin": "dunhuashi"
            },
            {
                "city": "敦煌市",
                "pinyin": "dunhuangshi"
            }
        ]
    },
    {
        "title": "T",
        "lists": [
            {
                "city": "调兵山市",
                "pinyin": "tiaobingshanshi"
            },
            {
                "city": "台北市",
                "pinyin": "taibeishi"
            },
            {
                "city": "台江区",
                "pinyin": "taijiangqu"
            },
            {
                "city": "台南市",
                "pinyin": "tainanshi"
            },
            {
                "city": "台中市",
                "pinyin": "taizhongshi"
            },
            {
                "city": "台州市",
                "pinyin": "taizhoushi"
            },
            {
                "city": "太仓市",
                "pinyin": "taicangshi"
            },
            {
                "city": "太原市",
                "pinyin": "taiyuanshi"
            },
            {
                "city": "泰安市",
                "pinyin": "taianshi"
            },
            {
                "city": "泰兴市",
                "pinyin": "taixingshi"
            },
            {
                "city": "泰州市",
                "pinyin": "taizhoushi"
            },
            {
                "city": "唐山市",
                "pinyin": "tangshanshi"
            },
            {
                "city": "洮南市",
                "pinyin": "taonanshi"
            },
            {
                "city": "桃源市",
                "pinyin": "taoyuanshi"
            },
            {
                "city": "滕州市",
                "pinyin": "tengzhoushi"
            },
            {
                "city": "天河区",
                "pinyin": "tianhequ"
            },
            {
                "city": "天门市",
                "pinyin": "tianmenshi"
            },
            {
                "city": "天桥区",
                "pinyin": "tianqiaoqu"
            },
            {
                "city": "天山区",
                "pinyin": "tianshanqu"
            },
            {
                "city": "天水市",
                "pinyin": "tianshuishi"
            },
            {
                "city": "天心区",
                "pinyin": "tianxinqu"
            },
            {
                "city": "天长市",
                "pinyin": "tianchangshi"
            },
            {
                "city": "铁力市",
                "pinyin": "tielishi"
            },
            {
                "city": "铁岭市",
                "pinyin": "tielingshi"
            },
            {
                "city": "铁西区",
                "pinyin": "tiexiqu"
            },
            {
                "city": "通河县",
                "pinyin": "tonghexian"
            },
            {
                "city": "通化市",
                "pinyin": "tonghuashi"
            },
            {
                "city": "通辽市",
                "pinyin": "tongliaoshi"
            },
            {
                "city": "通州区",
                "pinyin": "tongzhouqu"
            },
            {
                "city": "通州市",
                "pinyin": "tongzhoushi"
            },
            {
                "city": "同江市",
                "pinyin": "tongjiangshi"
            },
            {
                "city": "桐城市",
                "pinyin": "tongchengshi"
            },
            {
                "city": "桐庐县",
                "pinyin": "tongluxian"
            },
            {
                "city": "桐乡市",
                "pinyin": "tongxiangshi"
            },
            {
                "city": "铜川",
                "pinyin": "tongchuan"
            },
            {
                "city": "铜梁区",
                "pinyin": "tongliangqu"
            },
            {
                "city": "铜陵市",
                "pinyin": "tonglingshi"
            },
            {
                "city": "铜仁市",
                "pinyin": "tongrenshi"
            },
            {
                "city": "潼南区",
                "pinyin": "tongnanqu"
            },
            {
                "city": "头屯河区",
                "pinyin": "toutunhequ"
            },
            {
                "city": "图们市",
                "pinyin": "tumenshi"
            },
            {
                "city": "土默特左旗",
                "pinyin": "tumotezuoqi"
            },
            {
                "city": "托克托县",
                "pinyin": "tuoketuoxian"
            }
        ]
    },
    {
        "title": "E",
        "lists": [
            {
                "city": "额尔古纳市",
                "pinyin": "eergunashi"
            },
            {
                "city": "鄂尔多斯市",
                "pinyin": "eerduosishi"
            },
            {
                "city": "鄂州市",
                "pinyin": "ezhoushi"
            },
            {
                "city": "恩施市",
                "pinyin": "enshishi"
            },
            {
                "city": "二道区",
                "pinyin": "erdaoqu"
            },
            {
                "city": "二连浩特市",
                "pinyin": "erlianhaoteshi"
            },
            {
                "city": "二七区",
                "pinyin": "erqiqu"
            }
        ]
    },
    {
        "title": "F",
        "lists": [
            {
                "city": "法库县",
                "pinyin": "fakuxian"
            },
            {
                "city": "方正县",
                "pinyin": "fangzhengxian"
            },
            {
                "city": "防城港市",
                "pinyin": "fangchenggangshi"
            },
            {
                "city": "房山区",
                "pinyin": "fangshanqu"
            },
            {
                "city": "肥城市",
                "pinyin": "feichengshi"
            },
            {
                "city": "肥东县",
                "pinyin": "feidongxian"
            },
            {
                "city": "肥西县",
                "pinyin": "feixixian"
            },
            {
                "city": "丰城市",
                "pinyin": "fengchengshi"
            },
            {
                "city": "丰都县",
                "pinyin": "fengdouxian"
            },
            {
                "city": "丰台区",
                "pinyin": "fengtaiqu"
            },
            {
                "city": "丰镇市",
                "pinyin": "fengzhenshi"
            },
            {
                "city": "凤城市",
                "pinyin": "fengchengshi"
            },
            {
                "city": "奉化市",
                "pinyin": "fenghuashi"
            },
            {
                "city": "奉节县",
                "pinyin": "fengjiexian"
            },
            {
                "city": "芙蓉区",
                "pinyin": "furongqu"
            },
            {
                "city": "佛山市",
                "pinyin": "foshanshi"
            },
            {
                "city": "涪陵区",
                "pinyin": "fulingqu"
            },
            {
                "city": "福安市",
                "pinyin": "fuanshi"
            },
            {
                "city": "福鼎市",
                "pinyin": "fudingshi"
            },
            {
                "city": "福清市",
                "pinyin": "fuqingshi"
            },
            {
                "city": "福州市",
                "pinyin": "fuzhoushi"
            },
            {
                "city": "抚顺市",
                "pinyin": "fushunshi"
            },
            {
                "city": "抚州市",
                "pinyin": "fuzhoushi"
            },
            {
                "city": "阜康市",
                "pinyin": "fukangshi"
            },
            {
                "city": "阜新市",
                "pinyin": "fuxinshi"
            },
            {
                "city": "阜阳市",
                "pinyin": "fuyangshi"
            },
            {
                "city": "富德市",
                "pinyin": "fudeshi"
            },
            {
                "city": "富锦市",
                "pinyin": "fujinshi"
            },
            {
                "city": "富民县",
                "pinyin": "fuminxian"
            },
            {
                "city": "富阳区",
                "pinyin": "fuyangqu"
            },
            {
                "city": "富阳市",
                "pinyin": "fuyangshi"
            }
        ]
    },
    {
        "title": "P",
        "lists": [
            {
                "city": "番禺区",
                "pinyin": "panyuqu"
            },
            {
                "city": "攀枝花市",
                "pinyin": "panzhihuashi"
            },
            {
                "city": "盘锦市",
                "pinyin": "panjinshi"
            },
            {
                "city": "盘龙区",
                "pinyin": "panlongqu"
            },
            {
                "city": "磐石市",
                "pinyin": "panshishi"
            },
            {
                "city": "彭水苗族土家族自治县",
                "pinyin": "pengshuimiaozutujiazuzizhixian"
            },
            {
                "city": "彭州市",
                "pinyin": "pengzhoushi"
            },
            {
                "city": "邳州市",
                "pinyin": "pizhoushi"
            },
            {
                "city": "郫县",
                "pinyin": "pixian"
            },
            {
                "city": "平顶山市",
                "pinyin": "pingdingshanshi"
            },
            {
                "city": "平度市",
                "pinyin": "pingdushi"
            },
            {
                "city": "平房区",
                "pinyin": "pingfangqu"
            },
            {
                "city": "平谷区",
                "pinyin": "pingguqu"
            },
            {
                "city": "平湖市",
                "pinyin": "pinghushi"
            },
            {
                "city": "平凉市",
                "pinyin": "pingliangshi"
            },
            {
                "city": "平山县",
                "pinyin": "pingshanxian"
            },
            {
                "city": "平潭县",
                "pinyin": "pingtanxian"
            },
            {
                "city": "平阴县",
                "pinyin": "pingyinxian"
            },
            {
                "city": "凭祥市",
                "pinyin": "pingxiangshi"
            },
            {
                "city": "萍乡市",
                "pinyin": "pingxiangshi"
            },
            {
                "city": "莆田市",
                "pinyin": "putianshi"
            },
            {
                "city": "蒲江县",
                "pinyin": "pujiangxian"
            },
            {
                "city": "濮阳市",
                "pinyin": "puyangshi"
            },
            {
                "city": "浦东新区",
                "pinyin": "pudongxinqu"
            },
            {
                "city": "浦口区",
                "pinyin": "pukouqu"
            },
            {
                "city": "普兰店市",
                "pinyin": "pulandianshi"
            },
            {
                "city": "普陀区",
                "pinyin": "putuoqu"
            }
        ]
    },
    {
        "title": "G",
        "lists": [
            {
                "city": "盖州市",
                "pinyin": "gaizhoushi"
            },
            {
                "city": "甘南州",
                "pinyin": "gannanzhou"
            },
            {
                "city": "甘孜州",
                "pinyin": "ganzizhou"
            },
            {
                "city": "赣州市",
                "pinyin": "ganzhoushi"
            },
            {
                "city": "钢城区",
                "pinyin": "gangchengqu"
            },
            {
                "city": "皋兰县",
                "pinyin": "gaolanxian"
            },
            {
                "city": "高安市",
                "pinyin": "gaoanshi"
            },
            {
                "city": "高碑店市",
                "pinyin": "gaobeidianshi"
            },
            {
                "city": "高淳县",
                "pinyin": "gaochunxian"
            },
            {
                "city": "高陵区",
                "pinyin": "gaolingqu"
            },
            {
                "city": "高雄市",
                "pinyin": "gaoxiongshi"
            },
            {
                "city": "高邑县",
                "pinyin": "gaoyixian"
            },
            {
                "city": "高邮市",
                "pinyin": "gaoyoushi"
            },
            {
                "city": "藁城区",
                "pinyin": "gaochengqu"
            },
            {
                "city": "格尔木市",
                "pinyin": "geermushi"
            },
            {
                "city": "个旧市",
                "pinyin": "gejiushi"
            },
            {
                "city": "根河市",
                "pinyin": "genheshi"
            },
            {
                "city": "公主岭市",
                "pinyin": "gongzhulingshi"
            },
            {
                "city": "巩义市",
                "pinyin": "gongyishi"
            },
            {
                "city": "拱墅区",
                "pinyin": "gongshuqu"
            },
            {
                "city": "鼓楼区",
                "pinyin": "gulouqu"
            },
            {
                "city": "固原市",
                "pinyin": "guyuanshi"
            },
            {
                "city": "观山湖区",
                "pinyin": "guanshanhuqu"
            },
            {
                "city": "官渡区",
                "pinyin": "guanduqu"
            },
            {
                "city": "管城区",
                "pinyin": "guanchengqu"
            },
            {
                "city": "灌南县",
                "pinyin": "guannanxian"
            },
            {
                "city": "广安市",
                "pinyin": "guanganshi"
            },
            {
                "city": "广丰县",
                "pinyin": "guangfengxian"
            },
            {
                "city": "广水市",
                "pinyin": "guangshuishi"
            },
            {
                "city": "广元市",
                "pinyin": "guangyuanshi"
            },
            {
                "city": "广州市",
                "pinyin": "guangzhoushi"
            },
            {
                "city": "贵港市",
                "pinyin": "guigangshi"
            },
            {
                "city": "贵溪市",
                "pinyin": "guixishi"
            },
            {
                "city": "贵阳市",
                "pinyin": "guiyangshi"
            },
            {
                "city": "桂林市",
                "pinyin": "guilinshi"
            },
            {
                "city": "桂平市",
                "pinyin": "guipingshi"
            },
            {
                "city": "果洛市",
                "pinyin": "guoluoshi"
            }
        ]
    },
    {
        "title": "H",
        "lists": [
            {
                "city": "哈尔滨市",
                "pinyin": "haerbinshi"
            },
            {
                "city": "哈密市",
                "pinyin": "hamishi"
            },
            {
                "city": "海北市",
                "pinyin": "haibeishi"
            },
            {
                "city": "海城市",
                "pinyin": "haichengshi"
            },
            {
                "city": "海淀区",
                "pinyin": "haidianqu"
            },
            {
                "city": "海东市",
                "pinyin": "haidongshi"
            },
            {
                "city": "海口市",
                "pinyin": "haikoushi"
            },
            {
                "city": "海林市",
                "pinyin": "hailinshi"
            },
            {
                "city": "海伦市",
                "pinyin": "hailunshi"
            },
            {
                "city": "海门市",
                "pinyin": "haimenshi"
            },
            {
                "city": "海南市",
                "pinyin": "hainanshi"
            },
            {
                "city": "海宁市",
                "pinyin": "hainingshi"
            },
            {
                "city": "海西市",
                "pinyin": "haixishi"
            },
            {
                "city": "海珠区",
                "pinyin": "haizhuqu"
            },
            {
                "city": "邯郸市",
                "pinyin": "handanshi"
            },
            {
                "city": "汉川市",
                "pinyin": "hanchuanshi"
            },
            {
                "city": "汉南区",
                "pinyin": "hannanqu"
            },
            {
                "city": "汉阳区",
                "pinyin": "hanyangqu"
            },
            {
                "city": "汉中",
                "pinyin": "hanzhong"
            },
            {
                "city": "杭州市",
                "pinyin": "hangzhoushi"
            },
            {
                "city": "合川区",
                "pinyin": "hechuanqu"
            },
            {
                "city": "合肥市",
                "pinyin": "hefeishi"
            },
            {
                "city": "合山市",
                "pinyin": "heshanshi"
            },
            {
                "city": "合作市",
                "pinyin": "hezuoshi"
            },
            {
                "city": "和林格尔县",
                "pinyin": "helingeerxian"
            },
            {
                "city": "和龙市",
                "pinyin": "helongshi"
            },
            {
                "city": "和平区",
                "pinyin": "hepingqu"
            },
            {
                "city": "和田市",
                "pinyin": "hetianshi"
            },
            {
                "city": "河北区",
                "pinyin": "hebeiqu"
            },
            {
                "city": "河池市",
                "pinyin": "hechishi"
            },
            {
                "city": "河东区",
                "pinyin": "hedongqu"
            },
            {
                "city": "河间市",
                "pinyin": "hejianshi"
            },
            {
                "city": "河西区",
                "pinyin": "hexiqu"
            },
            {
                "city": "河源市",
                "pinyin": "heyuanshi"
            },
            {
                "city": "菏泽市",
                "pinyin": "hezeshi"
            },
            {
                "city": "贺兰县",
                "pinyin": "helanxian"
            },
            {
                "city": "贺州市",
                "pinyin": "hezhoushi"
            },
            {
                "city": "鹤壁市",
                "pinyin": "hebishi"
            },
            {
                "city": "鹤岗市",
                "pinyin": "hegangshi"
            },
            {
                "city": "黑河市",
                "pinyin": "heiheshi"
            },
            {
                "city": "横县",
                "pinyin": "hengxian"
            },
            {
                "city": "衡水市",
                "pinyin": "hengshuishi"
            },
            {
                "city": "衡阳市",
                "pinyin": "hengyangshi"
            },
            {
                "city": "红古区",
                "pinyin": "hongguqu"
            },
            {
                "city": "红谷滩区",
                "pinyin": "honggutanqu"
            },
            {
                "city": "红桥区",
                "pinyin": "hongqiaoqu"
            },
            {
                "city": "虹口区",
                "pinyin": "hongkouqu"
            },
            {
                "city": "洪湖市",
                "pinyin": "honghushi"
            },
            {
                "city": "洪山区",
                "pinyin": "hongshanqu"
            },
            {
                "city": "呼和浩特市",
                "pinyin": "huhehaoteshi"
            },
            {
                "city": "呼兰区",
                "pinyin": "hulanqu"
            },
            {
                "city": "呼伦贝尔市",
                "pinyin": "hulunbeiershi"
            },
            {
                "city": "葫芦岛市",
                "pinyin": "huludaoshi"
            },
            {
                "city": "湖州市",
                "pinyin": "huzhoushi"
            },
            {
                "city": "虎林市",
                "pinyin": "hulinshi"
            },
            {
                "city": "鄠邑区",
                "pinyin": "huyiqu"
            },
            {
                "city": "花都区",
                "pinyin": "huadouqu"
            },
            {
                "city": "花溪区",
                "pinyin": "huaxiqu"
            },
            {
                "city": "桦甸市",
                "pinyin": "huadianshi"
            },
            {
                "city": "怀化市",
                "pinyin": "huaihuashi"
            },
            {
                "city": "怀柔区",
                "pinyin": "huairouqu"
            },
            {
                "city": "淮安市",
                "pinyin": "huaianshi"
            },
            {
                "city": "淮北市",
                "pinyin": "huaibeishi"
            },
            {
                "city": "淮南市",
                "pinyin": "huainanshi"
            },
            {
                "city": "槐荫区",
                "pinyin": "huaiyinqu"
            },
            {
                "city": "皇姑区",
                "pinyin": "huangguqu"
            },
            {
                "city": "黄陂区",
                "pinyin": "huangbeiqu"
            },
            {
                "city": "黄冈市",
                "pinyin": "huanggangshi"
            },
            {
                "city": "黄骅市",
                "pinyin": "huanghuashi"
            },
            {
                "city": "黄埔区",
                "pinyin": "huangpuqu"
            },
            {
                "city": "黄浦区",
                "pinyin": "huangpuqu"
            },
            {
                "city": "黄山市",
                "pinyin": "huangshanshi"
            },
            {
                "city": "黄石市",
                "pinyin": "huangshishi"
            },
            {
                "city": "湟源县",
                "pinyin": "huangyuanxian"
            },
            {
                "city": "湟中区",
                "pinyin": "huangzhongqu"
            },
            {
                "city": "珲春市",
                "pinyin": "huichunshi"
            },
            {
                "city": "辉县市",
                "pinyin": "huixianshi"
            },
            {
                "city": "回民区",
                "pinyin": "huiminqu"
            },
            {
                "city": "惠济区",
                "pinyin": "huijiqu"
            },
            {
                "city": "惠州市",
                "pinyin": "huizhoushi"
            },
            {
                "city": "浑南区",
                "pinyin": "hunnanqu"
            },
            {
                "city": "霍林郭勒市",
                "pinyin": "huolinguoleshi"
            }
        ]
    },
    {
        "title": "J",
        "lists": [
            {
                "city": "鸡西市",
                "pinyin": "jixishi"
            },
            {
                "city": "吉安市",
                "pinyin": "jianshi"
            },
            {
                "city": "吉林市",
                "pinyin": "jilinshi"
            },
            {
                "city": "吉首市",
                "pinyin": "jishoushi"
            },
            {
                "city": "即墨市",
                "pinyin": "jimoshi"
            },
            {
                "city": "集安市",
                "pinyin": "jianshi"
            },
            {
                "city": "济南市",
                "pinyin": "jinanshi"
            },
            {
                "city": "济宁市",
                "pinyin": "jiningshi"
            },
            {
                "city": "济阳区",
                "pinyin": "jiyangqu"
            },
            {
                "city": "济源市",
                "pinyin": "jiyuanshi"
            },
            {
                "city": "蓟州区",
                "pinyin": "jizhouqu"
            },
            {
                "city": "冀州市",
                "pinyin": "jizhoushi"
            },
            {
                "city": "佳木斯市",
                "pinyin": "jiamusishi"
            },
            {
                "city": "嘉定区",
                "pinyin": "jiadingqu"
            },
            {
                "city": "嘉兴市",
                "pinyin": "jiaxingshi"
            },
            {
                "city": "嘉峪关市",
                "pinyin": "jiayuguanshi"
            },
            {
                "city": "郏县",
                "pinyin": "jiaxian"
            },
            {
                "city": "尖草坪区",
                "pinyin": "jiancaopingqu"
            },
            {
                "city": "建德市",
                "pinyin": "jiandeshi"
            },
            {
                "city": "建瓯市",
                "pinyin": "jianoushi"
            },
            {
                "city": "建阳市",
                "pinyin": "jianyangshi"
            },
            {
                "city": "建邺区",
                "pinyin": "jianyequ"
            },
            {
                "city": "江岸区",
                "pinyin": "jianganqu"
            },
            {
                "city": "江北区",
                "pinyin": "jiangbeiqu"
            },
            {
                "city": "江都市",
                "pinyin": "jiangdoushi"
            },
            {
                "city": "江干区",
                "pinyin": "jiangganqu"
            },
            {
                "city": "江汉区",
                "pinyin": "jianghanqu"
            },
            {
                "city": "江津区",
                "pinyin": "jiangjinqu"
            },
            {
                "city": "江门市",
                "pinyin": "jiangmenshi"
            },
            {
                "city": "江南区",
                "pinyin": "jiangnanqu"
            },
            {
                "city": "江宁区",
                "pinyin": "jiangningqu"
            },
            {
                "city": "江山市",
                "pinyin": "jiangshanshi"
            },
            {
                "city": "江夏区",
                "pinyin": "jiangxiaqu"
            },
            {
                "city": "江阴市",
                "pinyin": "jiangyinshi"
            },
            {
                "city": "姜堰市",
                "pinyin": "jiangyanshi"
            },
            {
                "city": "胶南市",
                "pinyin": "jiaonanshi"
            },
            {
                "city": "胶州市",
                "pinyin": "jiaozhoushi"
            },
            {
                "city": "蛟河市",
                "pinyin": "jiaoheshi"
            },
            {
                "city": "焦作市",
                "pinyin": "jiaozuoshi"
            },
            {
                "city": "揭阳市",
                "pinyin": "jieyangshi"
            },
            {
                "city": "界首市",
                "pinyin": "jieshoushi"
            },
            {
                "city": "金昌市",
                "pinyin": "jinchangshi"
            },
            {
                "city": "金凤区",
                "pinyin": "jinfengqu"
            },
            {
                "city": "金华市",
                "pinyin": "jinhuashi"
            },
            {
                "city": "金牛区",
                "pinyin": "jinniuqu"
            },
            {
                "city": "金山区",
                "pinyin": "jinshanqu"
            },
            {
                "city": "金水区",
                "pinyin": "jinshuiqu"
            },
            {
                "city": "金坛市",
                "pinyin": "jintanshi"
            },
            {
                "city": "金堂县",
                "pinyin": "jintangxian"
            },
            {
                "city": "津南区",
                "pinyin": "jinnanqu"
            },
            {
                "city": "津市市",
                "pinyin": "jinshishi"
            },
            {
                "city": "锦江区",
                "pinyin": "jinjiangqu"
            },
            {
                "city": "锦州市",
                "pinyin": "jinzhoushi"
            },
            {
                "city": "进贤县",
                "pinyin": "jinxianxian"
            },
            {
                "city": "晋安区",
                "pinyin": "jinanqu"
            },
            {
                "city": "晋城市",
                "pinyin": "jinchengshi"
            },
            {
                "city": "晋江市",
                "pinyin": "jinjiangshi"
            },
            {
                "city": "晋宁区",
                "pinyin": "jinningqu"
            },
            {
                "city": "晋源区",
                "pinyin": "jinyuanqu"
            },
            {
                "city": "晋中市",
                "pinyin": "jinzhongshi"
            },
            {
                "city": "晋州市",
                "pinyin": "jinzhoushi"
            },
            {
                "city": "荆门市",
                "pinyin": "jingmenshi"
            },
            {
                "city": "荆州市",
                "pinyin": "jingzhoushi"
            },
            {
                "city": "井冈山市",
                "pinyin": "jinggangshanshi"
            },
            {
                "city": "井陉矿区",
                "pinyin": "jingxingkuangqu"
            },
            {
                "city": "井陉县",
                "pinyin": "jingxingxian"
            },
            {
                "city": "景德镇市",
                "pinyin": "jingdezhenshi"
            },
            {
                "city": "景洪市",
                "pinyin": "jinghongshi"
            },
            {
                "city": "靖江市",
                "pinyin": "jingjiangshi"
            },
            {
                "city": "静安区",
                "pinyin": "jinganqu"
            },
            {
                "city": "静海区",
                "pinyin": "jinghaiqu"
            },
            {
                "city": "九江市",
                "pinyin": "jiujiangshi"
            },
            {
                "city": "九龙坡区",
                "pinyin": "jiulongpoqu"
            },
            {
                "city": "九台区",
                "pinyin": "jiutaiqu"
            },
            {
                "city": "九台市",
                "pinyin": "jiutaishi"
            },
            {
                "city": "酒泉市",
                "pinyin": "jiuquanshi"
            },
            {
                "city": "句容市",
                "pinyin": "jurongshi"
            }
        ]
    },
    {
        "title": "K",
        "lists": [
            {
                "city": "喀什市",
                "pinyin": "kashenshi"
            },
            {
                "city": "开封市",
                "pinyin": "kaifengshi"
            },
            {
                "city": "开福区",
                "pinyin": "kaifuqu"
            },
            {
                "city": "开阳县",
                "pinyin": "kaiyangxian"
            },
            {
                "city": "开原市",
                "pinyin": "kaiyuanshi"
            },
            {
                "city": "开远市",
                "pinyin": "kaiyuanshi"
            },
            {
                "city": "开州区",
                "pinyin": "kaizhouqu"
            },
            {
                "city": "康平县",
                "pinyin": "kangpingxian"
            },
            {
                "city": "克拉玛依市",
                "pinyin": "kelamayishi"
            },
            {
                "city": "库尔勒市",
                "pinyin": "kuerleshi"
            },
            {
                "city": "宽城区",
                "pinyin": "kuanchengqu"
            },
            {
                "city": "奎屯市",
                "pinyin": "kuitunshi"
            },
            {
                "city": "昆明市",
                "pinyin": "kunmingshi"
            },
            {
                "city": "昆山市",
                "pinyin": "kunshanshi"
            }
        ]
    },
    {
        "title": "L",
        "lists": [
            {
                "city": "拉萨市",
                "pinyin": "lasashi"
            },
            {
                "city": "来宾市",
                "pinyin": "laibinshi"
            },
            {
                "city": "莱芜区",
                "pinyin": "laiwuqu"
            },
            {
                "city": "莱芜市",
                "pinyin": "laiwushi"
            },
            {
                "city": "莱西市",
                "pinyin": "laixishi"
            },
            {
                "city": "兰溪市",
                "pinyin": "lanxishi"
            },
            {
                "city": "兰州市",
                "pinyin": "lanzhoushi"
            },
            {
                "city": "廊坊市",
                "pinyin": "langfangshi"
            },
            {
                "city": "老河口市",
                "pinyin": "laohekoushi"
            },
            {
                "city": "乐陵市",
                "pinyin": "lelingshi"
            },
            {
                "city": "乐平市",
                "pinyin": "lepingshi"
            },
            {
                "city": "乐清市",
                "pinyin": "leqingshi"
            },
            {
                "city": "乐山市",
                "pinyin": "leshanshi"
            },
            {
                "city": "耒阳市",
                "pinyin": "leiyangshi"
            },
            {
                "city": "冷水江市",
                "pinyin": "lengshuijiangshi"
            },
            {
                "city": "醴陵市",
                "pinyin": "lilingshi"
            },
            {
                "city": "历城区",
                "pinyin": "lichengqu"
            },
            {
                "city": "历下区",
                "pinyin": "lixiaqu"
            },
            {
                "city": "丽江市",
                "pinyin": "lijiangshi"
            },
            {
                "city": "丽水市",
                "pinyin": "lishuishi"
            },
            {
                "city": "利川市",
                "pinyin": "lichuanshi"
            },
            {
                "city": "荔湾区",
                "pinyin": "liwanqu"
            },
            {
                "city": "溧水县",
                "pinyin": "lishuixian"
            },
            {
                "city": "溧阳市",
                "pinyin": "liyangshi"
            },
            {
                "city": "连江县",
                "pinyin": "lianjiangxian"
            },
            {
                "city": "连云港市",
                "pinyin": "lianyungangshi"
            },
            {
                "city": "莲湖区",
                "pinyin": "lianhuqu"
            },
            {
                "city": "涟源市",
                "pinyin": "lianyuanshi"
            },
            {
                "city": "良庆区",
                "pinyin": "liangqingqu"
            },
            {
                "city": "凉山州",
                "pinyin": "liangshanzhou"
            },
            {
                "city": "梁平区",
                "pinyin": "liangpingqu"
            },
            {
                "city": "辽阳市",
                "pinyin": "liaoyangshi"
            },
            {
                "city": "辽源市",
                "pinyin": "liaoyuanshi"
            },
            {
                "city": "辽中区",
                "pinyin": "liaozhongqu"
            },
            {
                "city": "聊城市",
                "pinyin": "liaochengshi"
            },
            {
                "city": "林州市",
                "pinyin": "linzhoushi"
            },
            {
                "city": "临安区",
                "pinyin": "linanqu"
            },
            {
                "city": "临安市",
                "pinyin": "linanshi"
            },
            {
                "city": "临沧市",
                "pinyin": "lincangshi"
            },
            {
                "city": "临汾市",
                "pinyin": "linfenshi"
            },
            {
                "city": "临海市",
                "pinyin": "linhaishi"
            },
            {
                "city": "临河市",
                "pinyin": "linheshi"
            },
            {
                "city": "临江市",
                "pinyin": "linjiangshi"
            },
            {
                "city": "临潼区",
                "pinyin": "lintongqu"
            },
            {
                "city": "临夏市",
                "pinyin": "linxiashi"
            },
            {
                "city": "临湘市",
                "pinyin": "linxiangshi"
            },
            {
                "city": "临沂市",
                "pinyin": "linyishi"
            },
            {
                "city": "灵宝市",
                "pinyin": "lingbaoshi"
            },
            {
                "city": "灵寿县",
                "pinyin": "lingshouxian"
            },
            {
                "city": "灵武市",
                "pinyin": "lingwushi"
            },
            {
                "city": "凌海市",
                "pinyin": "linghaishi"
            },
            {
                "city": "凌源市",
                "pinyin": "lingyuanshi"
            },
            {
                "city": "浏阳市",
                "pinyin": "liuyangshi"
            },
            {
                "city": "柳州市",
                "pinyin": "liuzhoushi"
            },
            {
                "city": "六安市",
                "pinyin": "liuanshi"
            },
            {
                "city": "六合区",
                "pinyin": "liuhequ"
            },
            {
                "city": "六盘水市",
                "pinyin": "liupanshuishi"
            },
            {
                "city": "龙海市",
                "pinyin": "longhaishi"
            },
            {
                "city": "龙华区",
                "pinyin": "longhuaqu"
            },
            {
                "city": "龙井市",
                "pinyin": "longjingshi"
            },
            {
                "city": "龙口市",
                "pinyin": "longkoushi"
            },
            {
                "city": "龙泉市",
                "pinyin": "longquanshi"
            },
            {
                "city": "龙泉驿区",
                "pinyin": "longquanyiqu"
            },
            {
                "city": "龙岩市",
                "pinyin": "longyanshi"
            },
            {
                "city": "隆安县",
                "pinyin": "longanxian"
            },
            {
                "city": "陇南市",
                "pinyin": "longnanshi"
            },
            {
                "city": "娄底市",
                "pinyin": "loudishi"
            },
            {
                "city": "娄烦县",
                "pinyin": "loufanxian"
            },
            {
                "city": "庐江县",
                "pinyin": "lujiangxian"
            },
            {
                "city": "庐阳区",
                "pinyin": "luyangqu"
            },
            {
                "city": "泸州市",
                "pinyin": "luzhoushi"
            },
            {
                "city": "鹿泉区",
                "pinyin": "luquanqu"
            },
            {
                "city": "鹿泉市",
                "pinyin": "luquanshi"
            },
            {
                "city": "禄劝县彝族苗族自治县",
                "pinyin": "luquanxianyizumiaozuzizhixian"
            },
            {
                "city": "栾城区",
                "pinyin": "luanchengqu"
            },
            {
                "city": "罗源县",
                "pinyin": "luoyuanxian"
            },
            {
                "city": "洛阳市",
                "pinyin": "luoyangshi"
            },
            {
                "city": "漯河市",
                "pinyin": "luotaheshi"
            },
            {
                "city": "吕梁市",
                "pinyin": "lüliangshi"
            },
            {
                "city": "绿园区",
                "pinyin": "lüyuanqu"
            }
        ]
    },
    {
        "title": "M",
        "lists": [
            {
                "city": "麻城市",
                "pinyin": "machengshi"
            },
            {
                "city": "马鞍山市",
                "pinyin": "maanshanshi"
            },
            {
                "city": "马山县",
                "pinyin": "mashanxian"
            },
            {
                "city": "马尾区",
                "pinyin": "mayiqu"
            },
            {
                "city": "满洲里市",
                "pinyin": "manzhoulishi"
            },
            {
                "city": "茂名市",
                "pinyin": "maomingshi"
            },
            {
                "city": "眉山市",
                "pinyin": "meishanshi"
            },
            {
                "city": "梅河口市",
                "pinyin": "meihekoushi"
            },
            {
                "city": "梅州市",
                "pinyin": "meizhoushi"
            },
            {
                "city": "美兰区",
                "pinyin": "meilanqu"
            },
            {
                "city": "门头沟区",
                "pinyin": "mentougouqu"
            },
            {
                "city": "孟州市",
                "pinyin": "mengzhoushi"
            },
            {
                "city": "米东区",
                "pinyin": "midongqu"
            },
            {
                "city": "米泉市",
                "pinyin": "miquanshi"
            },
            {
                "city": "汨罗市",
                "pinyin": "miluoshi"
            },
            {
                "city": "密山市",
                "pinyin": "mishanshi"
            },
            {
                "city": "密云县",
                "pinyin": "miyunxian"
            },
            {
                "city": "绵阳市",
                "pinyin": "mianyangshi"
            },
            {
                "city": "闵行区",
                "pinyin": "minxingqu"
            },
            {
                "city": "闽侯县",
                "pinyin": "minhouxian"
            },
            {
                "city": "闽清县",
                "pinyin": "minqingxian"
            },
            {
                "city": "明光市",
                "pinyin": "mingguangshi"
            },
            {
                "city": "牡丹江市",
                "pinyin": "mudanjiangshi"
            },
            {
                "city": "木兰县",
                "pinyin": "mulanxian"
            },
            {
                "city": "穆棱市",
                "pinyin": "mulengshi"
            }
        ]
    },
    {
        "title": "N",
        "lists": [
            {
                "city": "南安市",
                "pinyin": "nananshi"
            },
            {
                "city": "南岸区",
                "pinyin": "nananqu"
            },
            {
                "city": "南昌市",
                "pinyin": "nanchangshi"
            },
            {
                "city": "南昌县",
                "pinyin": "nanchangxian"
            },
            {
                "city": "南充市",
                "pinyin": "nanchongshi"
            },
            {
                "city": "南川区",
                "pinyin": "nanchuanqu"
            },
            {
                "city": "南岗区",
                "pinyin": "nangangqu"
            },
            {
                "city": "南港区",
                "pinyin": "nangangqu"
            },
            {
                "city": "南宫市",
                "pinyin": "nangongshi"
            },
            {
                "city": "南关区",
                "pinyin": "nanguanqu"
            },
            {
                "city": "南京市",
                "pinyin": "nanjingshi"
            },
            {
                "city": "南开区",
                "pinyin": "nankaiqu"
            },
            {
                "city": "南康市",
                "pinyin": "nankangshi"
            },
            {
                "city": "南明区",
                "pinyin": "nanmingqu"
            },
            {
                "city": "南宁市",
                "pinyin": "nanningshi"
            },
            {
                "city": "南平市",
                "pinyin": "nanpingshi"
            },
            {
                "city": "南沙区",
                "pinyin": "nanshaqu"
            },
            {
                "city": "南通市",
                "pinyin": "nantongshi"
            },
            {
                "city": "南阳市",
                "pinyin": "nanyangshi"
            },
            {
                "city": "讷河市",
                "pinyin": "neheshi"
            },
            {
                "city": "内湖区",
                "pinyin": "neihuqu"
            },
            {
                "city": "内江市",
                "pinyin": "neijiangshi"
            },
            {
                "city": "宁安市",
                "pinyin": "ninganshi"
            },
            {
                "city": "宁波市",
                "pinyin": "ningboshi"
            },
            {
                "city": "宁德市",
                "pinyin": "ningdeshi"
            },
            {
                "city": "宁国市",
                "pinyin": "ningguoshi"
            },
            {
                "city": "宁河区",
                "pinyin": "ninghequ"
            },
            {
                "city": "农安县",
                "pinyin": "nonganxian"
            }
        ]
    },
    {
        "title": "Q",
        "lists": [
            {
                "city": "七里河区",
                "pinyin": "qilihequ"
            },
            {
                "city": "七台河市",
                "pinyin": "qitaiheshi"
            },
            {
                "city": "栖霞区",
                "pinyin": "qixiaqu"
            },
            {
                "city": "齐齐哈尔市",
                "pinyin": "qiqihaershi"
            },
            {
                "city": "綦江区",
                "pinyin": "qijiangqu"
            },
            {
                "city": "启东市",
                "pinyin": "qidongshi"
            },
            {
                "city": "迁安市",
                "pinyin": "qiananshi"
            },
            {
                "city": "潜江市",
                "pinyin": "qianjiangshi"
            },
            {
                "city": "黔东南苗族侗族自治州(凯里市)",
                "pinyin": "qiandongnan"
            },
            {
                "city": "黔江区",
                "pinyin": "qianjiangqu"
            },
            {
                "city": "黔南布依族苗族自治州(都匀市)",
                "pinyin": "qiannan"
            },
            {
                "city": "黔西南布依族苗族自治州(兴义市)",
                "pinyin": "qianxinan"
            },
            {
                "city": "桥西区",
                "pinyin": "qiaoxiqu"
            },
            {
                "city": "硚口区",
                "pinyin": "qiaokouqu"
            },
            {
                "city": "钦州市",
                "pinyin": "qinzhoushi"
            },
            {
                "city": "秦淮区",
                "pinyin": "qinhuaiqu"
            },
            {
                "city": "秦皇岛市",
                "pinyin": "qinhuangdaoshi"
            },
            {
                "city": "沁阳市",
                "pinyin": "qinyangshi"
            },
            {
                "city": "青白江区",
                "pinyin": "qingbaijiangqu"
            },
            {
                "city": "青岛市",
                "pinyin": "qingdaoshi"
            },
            {
                "city": "青浦区",
                "pinyin": "qingpuqu"
            },
            {
                "city": "青山湖区",
                "pinyin": "qingshanhuqu"
            },
            {
                "city": "青山区",
                "pinyin": "qingshanqu"
            },
            {
                "city": "青铜峡市",
                "pinyin": "qingtongxiashi"
            },
            {
                "city": "青秀区",
                "pinyin": "qingxiuqu"
            },
            {
                "city": "青羊区",
                "pinyin": "qingyangqu"
            },
            {
                "city": "青云谱区",
                "pinyin": "qingyunpuqu"
            },
            {
                "city": "清水河县",
                "pinyin": "qingshuihexian"
            },
            {
                "city": "清徐县",
                "pinyin": "qingxuxian"
            },
            {
                "city": "清远市",
                "pinyin": "qingyuanshi"
            },
            {
                "city": "庆阳市",
                "pinyin": "qingyangshi"
            },
            {
                "city": "邛崃",
                "pinyin": "qionglai"
            },
            {
                "city": "琼海市",
                "pinyin": "qionghaishi"
            },
            {
                "city": "琼山区",
                "pinyin": "qiongshanqu"
            },
            {
                "city": "曲靖市",
                "pinyin": "qujingshi"
            },
            {
                "city": "衢州市",
                "pinyin": "quzhoushi"
            },
            {
                "city": "泉州市",
                "pinyin": "quanzhoushi"
            }
        ]
    },
    {
        "title": "R",
        "lists": [
            {
                "city": "任丘市",
                "pinyin": "renqiushi"
            },
            {
                "city": "日喀则市",
                "pinyin": "rikazeshi"
            },
            {
                "city": "日照市",
                "pinyin": "rizhaoshi"
            },
            {
                "city": "荣昌区",
                "pinyin": "rongchangqu"
            },
            {
                "city": "如皋市",
                "pinyin": "rugaoshi"
            },
            {
                "city": "汝州市",
                "pinyin": "ruzhoushi"
            },
            {
                "city": "瑞安市",
                "pinyin": "ruianshi"
            },
            {
                "city": "瑞昌市",
                "pinyin": "ruichangshi"
            },
            {
                "city": "瑞金市",
                "pinyin": "ruijinshi"
            }
        ]
    },
    {
        "title": "S",
        "lists": [
            {
                "city": "赛罕区",
                "pinyin": "saihanqu"
            },
            {
                "city": "三河市",
                "pinyin": "sanheshi"
            },
            {
                "city": "三门峡市",
                "pinyin": "sanmenxiashi"
            },
            {
                "city": "三明市",
                "pinyin": "sanmingshi"
            },
            {
                "city": "三清山",
                "pinyin": "sanqingshan"
            },
            {
                "city": "三亚市",
                "pinyin": "sanyashi"
            },
            {
                "city": "沙河市",
                "pinyin": "shaheshi"
            },
            {
                "city": "沙坪坝区",
                "pinyin": "shapingbaqu"
            },
            {
                "city": "沙依巴克区",
                "pinyin": "shayibakequ"
            },
            {
                "city": "厦门市",
                "pinyin": "shamenshi"
            },
            {
                "city": "汕头市",
                "pinyin": "shantoushi"
            },
            {
                "city": "汕尾市",
                "pinyin": "shanweishi"
            },
            {
                "city": "商河县",
                "pinyin": "shanghexian"
            },
            {
                "city": "商洛",
                "pinyin": "shangluo"
            },
            {
                "city": "商丘市",
                "pinyin": "shangqiushi"
            },
            {
                "city": "上城区",
                "pinyin": "shangchengqu"
            },
            {
                "city": "上街区",
                "pinyin": "shangjiequ"
            },
            {
                "city": "上林县",
                "pinyin": "shanglinxian"
            },
            {
                "city": "上饶市",
                "pinyin": "shangraoshi"
            },
            {
                "city": "上虞市",
                "pinyin": "shangyushi"
            },
            {
                "city": "尚志市",
                "pinyin": "shangzhishi"
            },
            {
                "city": "韶关市",
                "pinyin": "shaoguanshi"
            },
            {
                "city": "韶山市",
                "pinyin": "shaoshanshi"
            },
            {
                "city": "邵武市",
                "pinyin": "shaowushi"
            },
            {
                "city": "邵阳市",
                "pinyin": "shaoyangshi"
            },
            {
                "city": "绍兴市",
                "pinyin": "shaoxingshi"
            },
            {
                "city": "深泽县",
                "pinyin": "shenzexian"
            },
            {
                "city": "深圳市",
                "pinyin": "shenzhenshi"
            },
            {
                "city": "深州市",
                "pinyin": "shenzhoushi"
            },
            {
                "city": "沈北新区",
                "pinyin": "shenbeixinqu"
            },
            {
                "city": "沈河区",
                "pinyin": "shenhequ"
            },
            {
                "city": "沈阳市",
                "pinyin": "shenyangshi"
            },
            {
                "city": "嵊州市",
                "pinyin": "shengzhoushi"
            },
            {
                "city": "十堰市",
                "pinyin": "shiyanshi"
            },
            {
                "city": "石河子市",
                "pinyin": "shihezishi"
            },
            {
                "city": "石家庄市",
                "pinyin": "shijiazhuangshi"
            },
            {
                "city": "石景山区",
                "pinyin": "shijingshanqu"
            },
            {
                "city": "石林县彝族自治县",
                "pinyin": "shilinxianyizuzizhixian"
            },
            {
                "city": "石狮市",
                "pinyin": "shishishi"
            },
            {
                "city": "石首市",
                "pinyin": "shishoushi"
            },
            {
                "city": "石柱土家族自治县",
                "pinyin": "shizhutujiazuzizhixian"
            },
            {
                "city": "石嘴山市",
                "pinyin": "shizuishanshi"
            },
            {
                "city": "士林区",
                "pinyin": "shilinqu"
            },
            {
                "city": "市中区",
                "pinyin": "shizhongqu"
            },
            {
                "city": "舒兰市",
                "pinyin": "shulanshi"
            },
            {
                "city": "蜀山区",
                "pinyin": "shushanqu"
            },
            {
                "city": "双城区",
                "pinyin": "shuangchengqu"
            },
            {
                "city": "双城市",
                "pinyin": "shuangchengshi"
            },
            {
                "city": "双辽市",
                "pinyin": "shuangliaoshi"
            },
            {
                "city": "双流县",
                "pinyin": "shuangliuxian"
            },
            {
                "city": "双鸭山市",
                "pinyin": "shuangyashanshi"
            },
            {
                "city": "双阳区",
                "pinyin": "shuangyangqu"
            },
            {
                "city": "水磨沟区",
                "pinyin": "shuimogouqu"
            },
            {
                "city": "顺义区",
                "pinyin": "shunyiqu"
            },
            {
                "city": "朔州市",
                "pinyin": "shuozhoushi"
            },
            {
                "city": "四平市",
                "pinyin": "sipingshi"
            },
            {
                "city": "松北区",
                "pinyin": "songbeiqu"
            },
            {
                "city": "松江区",
                "pinyin": "songjiangqu"
            },
            {
                "city": "松山区",
                "pinyin": "songshanqu"
            },
            {
                "city": "松原市",
                "pinyin": "songyuanshi"
            },
            {
                "city": "松滋市",
                "pinyin": "songzishi"
            },
            {
                "city": "嵩明县",
                "pinyin": "songmingxian"
            },
            {
                "city": "苏家屯区",
                "pinyin": "sujiatunqu"
            },
            {
                "city": "苏州市",
                "pinyin": "suzhoushi"
            },
            {
                "city": "宿迁市",
                "pinyin": "suqianshi"
            },
            {
                "city": "宿州市",
                "pinyin": "suzhoushi"
            },
            {
                "city": "绥芬河市",
                "pinyin": "suifenheshi"
            },
            {
                "city": "绥化市",
                "pinyin": "suihuashi"
            },
            {
                "city": "随州市",
                "pinyin": "suizhoushi"
            },
            {
                "city": "遂宁市",
                "pinyin": "suiningshi"
            }
        ]
    },
    {
        "title": "W",
        "lists": [
            {
                "city": "瓦房店市",
                "pinyin": "wafangdianshi"
            },
            {
                "city": "万柏林区",
                "pinyin": "wanbolinqu"
            },
            {
                "city": "万华区",
                "pinyin": "wanhuaqu"
            },
            {
                "city": "万宁市",
                "pinyin": "wanningshi"
            },
            {
                "city": "万州区",
                "pinyin": "wanzhouqu"
            },
            {
                "city": "望城区",
                "pinyin": "wangchengqu"
            },
            {
                "city": "威海市",
                "pinyin": "weihaishi"
            },
            {
                "city": "潍坊市",
                "pinyin": "weifangshi"
            },
            {
                "city": "卫辉市",
                "pinyin": "weihuishi"
            },
            {
                "city": "未央区",
                "pinyin": "weiyangqu"
            },
            {
                "city": "渭南",
                "pinyin": "weinan"
            },
            {
                "city": "温江区",
                "pinyin": "wenjiangqu"
            },
            {
                "city": "温岭市",
                "pinyin": "wenlingshi"
            },
            {
                "city": "温州市",
                "pinyin": "wenzhoushi"
            },
            {
                "city": "文昌市",
                "pinyin": "wenchangshi"
            },
            {
                "city": "文山区",
                "pinyin": "wenshanqu"
            },
            {
                "city": "乌当区",
                "pinyin": "wudangqu"
            },
            {
                "city": "乌海市",
                "pinyin": "wuhaishi"
            },
            {
                "city": "乌兰察布市",
                "pinyin": "wulanchabushi"
            },
            {
                "city": "乌兰浩特市",
                "pinyin": "wulanhaoteshi"
            },
            {
                "city": "乌鲁木齐市",
                "pinyin": "wulumuqishi"
            },
            {
                "city": "乌鲁木齐县",
                "pinyin": "wulumuqixian"
            },
            {
                "city": "巫山县",
                "pinyin": "wushanxian"
            },
            {
                "city": "巫溪县",
                "pinyin": "wuxixian"
            },
            {
                "city": "无极县",
                "pinyin": "wujixian"
            },
            {
                "city": "无锡市",
                "pinyin": "wuxishi"
            },
            {
                "city": "芜湖市",
                "pinyin": "wuhushi"
            },
            {
                "city": "吴江市",
                "pinyin": "wujiangshi"
            },
            {
                "city": "吴忠市",
                "pinyin": "wuzhongshi"
            },
            {
                "city": "梧州市",
                "pinyin": "wuzhoushi"
            },
            {
                "city": "五常市",
                "pinyin": "wuchangshi"
            },
            {
                "city": "五大连池市",
                "pinyin": "wudalianchishi"
            },
            {
                "city": "五华区",
                "pinyin": "wuhuaqu"
            },
            {
                "city": "五指山市",
                "pinyin": "wuzhishanshi"
            },
            {
                "city": "武安市",
                "pinyin": "wuanshi"
            },
            {
                "city": "武昌区",
                "pinyin": "wuchangqu"
            },
            {
                "city": "武川县",
                "pinyin": "wuchuanxian"
            },
            {
                "city": "武冈市",
                "pinyin": "wugangshi"
            },
            {
                "city": "武汉市",
                "pinyin": "wuhanshi"
            },
            {
                "city": "武侯区",
                "pinyin": "wuhouqu"
            },
            {
                "city": "武隆区",
                "pinyin": "wulongqu"
            },
            {
                "city": "武鸣县",
                "pinyin": "wumingxian"
            },
            {
                "city": "武清区",
                "pinyin": "wuqingqu"
            },
            {
                "city": "武威市",
                "pinyin": "wuweishi"
            },
            {
                "city": "武穴市",
                "pinyin": "wuxueshi"
            },
            {
                "city": "武夷山市",
                "pinyin": "wuyishanshi"
            },
            {
                "city": "舞钢市",
                "pinyin": "wugangshi"
            }
        ]
    },
    {
        "title": "X",
        "lists": [
            {
                "city": "西安",
                "pinyin": "xian"
            },
            {
                "city": "西城区",
                "pinyin": "xichengqu"
            },
            {
                "city": "西固区",
                "pinyin": "xiguqu"
            },
            {
                "city": "西湖区",
                "pinyin": "xihuqu"
            },
            {
                "city": "西宁市",
                "pinyin": "xiningshi"
            },
            {
                "city": "西青区",
                "pinyin": "xiqingqu"
            },
            {
                "city": "西山区",
                "pinyin": "xishanqu"
            },
            {
                "city": "西夏区",
                "pinyin": "xixiaqu"
            },
            {
                "city": "西乡塘区",
                "pinyin": "xixiangtangqu"
            },
            {
                "city": "息烽县",
                "pinyin": "xifengxian"
            },
            {
                "city": "锡林浩特市",
                "pinyin": "xilinhaoteshi"
            },
            {
                "city": "辖城东区",
                "pinyin": "xiachengdongqu"
            },
            {
                "city": "下城区",
                "pinyin": "xiachengqu"
            },
            {
                "city": "下关区",
                "pinyin": "xiaguanqu"
            },
            {
                "city": "仙桃市",
                "pinyin": "xiantaoshi"
            },
            {
                "city": "咸宁市",
                "pinyin": "xianningshi"
            },
            {
                "city": "咸阳",
                "pinyin": "xianyang"
            },
            {
                "city": "香坊区",
                "pinyin": "xiangfangqu"
            },
            {
                "city": "湘潭市",
                "pinyin": "xiangtanshi"
            },
            {
                "city": "湘西州",
                "pinyin": "xiangxizhou"
            },
            {
                "city": "湘乡市",
                "pinyin": "xiangxiangshi"
            },
            {
                "city": "襄阳市",
                "pinyin": "xiangyangshi"
            },
            {
                "city": "项城市",
                "pinyin": "xiangchengshi"
            },
            {
                "city": "萧山区",
                "pinyin": "xiaoshanqu"
            },
            {
                "city": "小店区",
                "pinyin": "xiaodianqu"
            },
            {
                "city": "孝感市",
                "pinyin": "xiaoganshi"
            },
            {
                "city": "辛集市",
                "pinyin": "xinjishi"
            },
            {
                "city": "忻州市",
                "pinyin": "xinzhoushi"
            },
            {
                "city": "新城区",
                "pinyin": "xinchengqu"
            },
            {
                "city": "新都区",
                "pinyin": "xindouqu"
            },
            {
                "city": "新华区",
                "pinyin": "xinhuaqu"
            },
            {
                "city": "新建区",
                "pinyin": "xinjianqu"
            },
            {
                "city": "新津县",
                "pinyin": "xinjinxian"
            },
            {
                "city": "新乐市",
                "pinyin": "xinleshi"
            },
            {
                "city": "新密市",
                "pinyin": "xinmishi"
            },
            {
                "city": "新民市",
                "pinyin": "xinminshi"
            },
            {
                "city": "新市区",
                "pinyin": "xinshiqu"
            },
            {
                "city": "新泰市",
                "pinyin": "xintaishi"
            },
            {
                "city": "新乡市",
                "pinyin": "xinxiangshi"
            },
            {
                "city": "新沂市",
                "pinyin": "xinyishi"
            },
            {
                "city": "新余市",
                "pinyin": "xinyushi"
            },
            {
                "city": "新郑市",
                "pinyin": "xinzhengshi"
            },
            {
                "city": "新洲区",
                "pinyin": "xinzhouqu"
            },
            {
                "city": "信阳市",
                "pinyin": "xinyangshi"
            },
            {
                "city": "信义区",
                "pinyin": "xinyiqu"
            },
            {
                "city": "信州区",
                "pinyin": "xinzhouqu"
            },
            {
                "city": "邢台市",
                "pinyin": "xingtaishi"
            },
            {
                "city": "行唐县",
                "pinyin": "xingtangxian"
            },
            {
                "city": "荥阳市",
                "pinyin": "xingyangshi"
            },
            {
                "city": "兴城市",
                "pinyin": "xingchengshi"
            },
            {
                "city": "兴化市",
                "pinyin": "xinghuashi"
            },
            {
                "city": "兴宁区",
                "pinyin": "xingningqu"
            },
            {
                "city": "兴庆区",
                "pinyin": "xingqingqu"
            },
            {
                "city": "杏花岭区",
                "pinyin": "xinghualingqu"
            },
            {
                "city": "修文县",
                "pinyin": "xiuwenxian"
            },
            {
                "city": "秀山土家族苗族自治县",
                "pinyin": "xiushantujiazumiaozuzizhixian"
            },
            {
                "city": "秀英区",
                "pinyin": "xiuyingqu"
            },
            {
                "city": "徐汇区",
                "pinyin": "xuhuiqu"
            },
            {
                "city": "徐州市",
                "pinyin": "xuzhoushi"
            },
            {
                "city": "许昌市",
                "pinyin": "xuchangshi"
            },
            {
                "city": "宣城市",
                "pinyin": "xuanchengshi"
            },
            {
                "city": "宣威市",
                "pinyin": "xuanweishi"
            },
            {
                "city": "宣武区",
                "pinyin": "xuanwuqu"
            },
            {
                "city": "玄武区",
                "pinyin": "xuanwuqu"
            },
            {
                "city": "寻甸县回族彝族自治县",
                "pinyin": "xundianxianhuizuyizuzizhixian"
            }
        ]
    },
    {
        "title": "Y",
        "lists": [
            {
                "city": "牙克石市",
                "pinyin": "yakeshishi"
            },
            {
                "city": "雅安市",
                "pinyin": "yaanshi"
            },
            {
                "city": "烟台市",
                "pinyin": "yantaishi"
            },
            {
                "city": "延安",
                "pinyin": "yanan"
            },
            {
                "city": "延吉市",
                "pinyin": "yanjishi"
            },
            {
                "city": "延庆县",
                "pinyin": "yanqingxian"
            },
            {
                "city": "延寿县",
                "pinyin": "yanshouxian"
            },
            {
                "city": "盐城市",
                "pinyin": "yanchengshi"
            },
            {
                "city": "阎良区",
                "pinyin": "yanliangqu"
            },
            {
                "city": "兖州市",
                "pinyin": "yanzhoushi"
            },
            {
                "city": "偃师市",
                "pinyin": "yanshishi"
            },
            {
                "city": "雁塔区",
                "pinyin": "yantaqu"
            },
            {
                "city": "扬中市",
                "pinyin": "yangzhongshi"
            },
            {
                "city": "扬州市",
                "pinyin": "yangzhoushi"
            },
            {
                "city": "阳江市",
                "pinyin": "yangjiangshi"
            },
            {
                "city": "阳曲县",
                "pinyin": "yangquxian"
            },
            {
                "city": "阳泉市",
                "pinyin": "yangquanshi"
            },
            {
                "city": "杨浦区",
                "pinyin": "yangpuqu"
            },
            {
                "city": "瑶海区",
                "pinyin": "yaohaiqu"
            },
            {
                "city": "叶城市",
                "pinyin": "yechengshi"
            },
            {
                "city": "伊春市",
                "pinyin": "yichunshi"
            },
            {
                "city": "依兰县",
                "pinyin": "yilanxian"
            },
            {
                "city": "仪征市",
                "pinyin": "yizhengshi"
            },
            {
                "city": "宜宾市",
                "pinyin": "yibinshi"
            },
            {
                "city": "宜昌市",
                "pinyin": "yichangshi"
            },
            {
                "city": "宜城市",
                "pinyin": "yichengshi"
            },
            {
                "city": "宜春市",
                "pinyin": "yichunshi"
            },
            {
                "city": "宜都市",
                "pinyin": "yidoushi"
            },
            {
                "city": "宜良县",
                "pinyin": "yiliangxian"
            },
            {
                "city": "宜兴市",
                "pinyin": "yixingshi"
            },
            {
                "city": "宜州市",
                "pinyin": "yizhoushi"
            },
            {
                "city": "义马市",
                "pinyin": "yimashi"
            },
            {
                "city": "义乌市",
                "pinyin": "yiwushi"
            },
            {
                "city": "益阳市",
                "pinyin": "yiyangshi"
            },
            {
                "city": "银川市",
                "pinyin": "yinchuanshi"
            },
            {
                "city": "应城市",
                "pinyin": "yingchengshi"
            },
            {
                "city": "鹰潭市",
                "pinyin": "yingtanshi"
            },
            {
                "city": "迎泽区",
                "pinyin": "yingzequ"
            },
            {
                "city": "营口市",
                "pinyin": "yingkoushi"
            },
            {
                "city": "邕宁区 ",
                "pinyin": "yongningqu"
            },
            {
                "city": "永安市",
                "pinyin": "yonganshi"
            },
            {
                "city": "永城市",
                "pinyin": "yongchengshi"
            },
            {
                "city": "永川区",
                "pinyin": "yongchuanqu"
            },
            {
                "city": "永登县",
                "pinyin": "yongdengxian"
            },
            {
                "city": "永康市",
                "pinyin": "yongkangshi"
            },
            {
                "city": "永宁县",
                "pinyin": "yongningxian"
            },
            {
                "city": "永泰县",
                "pinyin": "yongtaixian"
            },
            {
                "city": "永州市",
                "pinyin": "yongzhoushi"
            },
            {
                "city": "酉阳土家族苗族自治县",
                "pinyin": "youyangtujiazumiaozuzizhixian"
            },
            {
                "city": "于洪区",
                "pinyin": "yuhongqu"
            },
            {
                "city": "余杭区",
                "pinyin": "yuhangqu"
            },
            {
                "city": "余姚市",
                "pinyin": "yuyaoshi"
            },
            {
                "city": "渝北区",
                "pinyin": "yubeiqu"
            },
            {
                "city": "渝中区",
                "pinyin": "yuzhongqu"
            },
            {
                "city": "榆林",
                "pinyin": "yulin"
            },
            {
                "city": "榆树市",
                "pinyin": "yushushi"
            },
            {
                "city": "榆中县",
                "pinyin": "yuzhongxian"
            },
            {
                "city": "雨花区",
                "pinyin": "yuhuaqu"
            },
            {
                "city": "禹州市",
                "pinyin": "yuzhoushi"
            },
            {
                "city": "玉林市",
                "pinyin": "yulinshi"
            },
            {
                "city": "玉门市",
                "pinyin": "yumenshi"
            },
            {
                "city": "玉泉区",
                "pinyin": "yuquanqu"
            },
            {
                "city": "玉树市",
                "pinyin": "yushushi"
            },
            {
                "city": "玉溪市",
                "pinyin": "yuxishi"
            },
            {
                "city": "裕华区",
                "pinyin": "yuhuaqu"
            },
            {
                "city": "元氏县",
                "pinyin": "yuanshixian"
            },
            {
                "city": "沅江市",
                "pinyin": "yuanjiangshi"
            },
            {
                "city": "岳麓区",
                "pinyin": "yueluqu"
            },
            {
                "city": "岳阳市",
                "pinyin": "yueyangshi"
            },
            {
                "city": "越秀区",
                "pinyin": "yuexiuqu"
            },
            {
                "city": "云浮市",
                "pinyin": "yunfushi"
            },
            {
                "city": "云岩区",
                "pinyin": "yunyanqu"
            },
            {
                "city": "云阳县",
                "pinyin": "yunyangxian"
            },
            {
                "city": "运城市",
                "pinyin": "yunchengshi"
            }
        ]
    },
    {
        "title": "Z",
        "lists": [
            {
                "city": "赞皇县",
                "pinyin": "zanhuangxian"
            },
            {
                "city": "枣阳市",
                "pinyin": "zaoyangshi"
            },
            {
                "city": "枣庄市",
                "pinyin": "zaozhuangshi"
            },
            {
                "city": "增城区",
                "pinyin": "zengchengqu"
            },
            {
                "city": "扎兰屯市",
                "pinyin": "zhalantunshi"
            },
            {
                "city": "湛江市",
                "pinyin": "zhanjiangshi"
            },
            {
                "city": "张家港市",
                "pinyin": "zhangjiagangshi"
            },
            {
                "city": "张家界市",
                "pinyin": "zhangjiajieshi"
            },
            {
                "city": "张家口市",
                "pinyin": "zhangjiakoushi"
            },
            {
                "city": "张掖市",
                "pinyin": "zhangyeshi"
            },
            {
                "city": "章丘区",
                "pinyin": "zhangqiuqu"
            },
            {
                "city": "漳平市",
                "pinyin": "zhangpingshi"
            },
            {
                "city": "漳州市",
                "pinyin": "zhangzhoushi"
            },
            {
                "city": "樟树市",
                "pinyin": "zhangshushi"
            },
            {
                "city": "昭通市",
                "pinyin": "zhaotongshi"
            },
            {
                "city": "赵县",
                "pinyin": "zhaoxian"
            },
            {
                "city": "肇东市",
                "pinyin": "zhaodongshi"
            },
            {
                "city": "肇庆市",
                "pinyin": "zhaoqingshi"
            },
            {
                "city": "镇江市",
                "pinyin": "zhenjiangshi"
            },
            {
                "city": "正定县",
                "pinyin": "zhengdingxian"
            },
            {
                "city": "郑州市",
                "pinyin": "zhengzhoushi"
            },
            {
                "city": "枝江市",
                "pinyin": "zhijiangshi"
            },
            {
                "city": "中牟县",
                "pinyin": "zhongmuxian"
            },
            {
                "city": "中山区",
                "pinyin": "zhongshanqu"
            },
            {
                "city": "中山市",
                "pinyin": "zhongshanshi"
            },
            {
                "city": "中卫市",
                "pinyin": "zhongweishi"
            },
            {
                "city": "中原区",
                "pinyin": "zhongyuanqu"
            },
            {
                "city": "中正区",
                "pinyin": "zhongzhengqu"
            },
            {
                "city": "忠县",
                "pinyin": "zhongxian"
            },
            {
                "city": "钟祥市",
                "pinyin": "zhongxiangshi"
            },
            {
                "city": "舟山市",
                "pinyin": "zhoushanshi"
            },
            {
                "city": "周口市",
                "pinyin": "zhoukoushi"
            },
            {
                "city": "珠海市",
                "pinyin": "zhuhaishi"
            },
            {
                "city": "株洲市",
                "pinyin": "zhuzhoushi"
            },
            {
                "city": "诸城市",
                "pinyin": "zhuchengshi"
            },
            {
                "city": "诸暨市",
                "pinyin": "zhujishi"
            },
            {
                "city": "驻马店市",
                "pinyin": "zhumadianshi"
            },
            {
                "city": "庄河市",
                "pinyin": "zhuangheshi"
            },
            {
                "city": "涿州市",
                "pinyin": "zhuozhoushi"
            },
            {
                "city": "资阳市",
                "pinyin": "ziyangshi"
            },
            {
                "city": "淄博市",
                "pinyin": "ziboshi"
            },
            {
                "city": "自贡市",
                "pinyin": "zigongshi"
            },
            {
                "city": "邹城市",
                "pinyin": "zouchengshi"
            },
            {
                "city": "遵化市",
                "pinyin": "zunhuashi"
            },
            {
                "city": "遵义市",
                "pinyin": "zunyishi"
            }
        ]
    }
];
