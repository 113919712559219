import {cityData} from '../components/common'
$(document).on('render:articles', () => {
  if ($('body').attr('data-action') == 'index') {
    document.querySelector('.back_btn').style.display='none'
    // 新闻轮播
    let items = document.querySelectorAll(".news_left_img .item"); //图片
    let points = document.querySelectorAll(".point") //点
    let cons = document.querySelectorAll('.news_left_swiper .con')
    let all = document.querySelector(".news_left")
    let list_item = document.querySelectorAll('.list_item')

    let index = 0;
    let time = 0; //定时器跳转参数初始化

    //清除active方法
    var clearActive = function () {
      for (let i = 0; i < items.length; i++) {
        items[i].className = 'item';
      }
      for (let j = 0; j < points.length; j++) {
        points[j].className = 'point';
      }
      for (let k = 0; k < cons.length; k++) {
        cons[k].className = 'con';
      }
      for (let l = 0; l < list_item.length; l++) {
        list_item[l].className = 'list_item';
      }
    }
    cons[0].className = 'con active'
    list_item[0].className = 'list_item active'


    //改变active方法
    var goIndex = function () {
      clearActive();
      items[index].className = 'item active';
      points[index].className = 'point active'
      cons[index].className = 'con active'
      list_item[index].className = 'list_item active'

    }

    //自动轮播
    var goRight = function () {
      if (index < 3) {
        index++;
      } else {
        index = 0;
      }
      goIndex();
    }

    for ( let i = 0; i < points.length; i++) {
      points[i].addEventListener('click', function () {
        var pointIndex = this.getAttribute('data-index')
        index = pointIndex;
        goIndex();
        time = 0; //计时器跳转清零
      })
    }
    //计时器
    var timer;

    function play() {
      timer = setInterval(() => {
        time++;
        if (time == 20) {
          goRight();
          time = 0;
        }
      }, 100)
    }
    play();
    //移入清除计时器
    all.onmousemove = function () {
      clearInterval(timer)
    }
    //移出启动计时器
    all.onmouseleave = function () {
      play();
    }
  }else if($('body').attr('data-action') == 'city_select'){
    // 头部
    let cityTitle = document.querySelector('#cityTitle')
    let eg_arr = cityData.map(v => {
      return v.title
    })
  
    let str1 = eg_arr.map(v => {
      return `
         <span>${v}</span>
      `
    }).join('')
    cityTitle.innerHTML = str1
  
  // 城市内容
    let spans = document.querySelectorAll('#cityTitle span')
    let city_content = document.querySelector('#city_content')
    let arr = []
    let egUrl=''
    spans.forEach((item, index) => {
      item.onclick = function () {
        let a = cityData.find((m, n) => {
          return m.title == item.innerText
        })
        arr = a.lists
        let str = arr.map((v, i) => {
          return `
        <li data-eg=${v.pinyin}>${v.city}</li>
      `
        }).join('')
        city_content.innerHTML = str
        let lis=document.querySelectorAll('li')
          lis.forEach((li=>{
            li.onclick=function(){
              window.open(`/news/city/info/${li.innerText}`)
            }
          }))
        spans.forEach((i, k) => {
          i.className = '';
        });
        item.className = 'active';
      }
    })
  
    // 默认展示热门城市
    spans[0].className='active'
    let hotCityArr=cityData.find(v=>{
      return v.title='热门城市'
    })
    let hotCity=hotCityArr.lists.map(v=>{
      return `
        <li data-eg=${v.pinyin}>${v.city}</li>
      `
    }).join('')
    city_content.innerHTML=hotCity

    let lis = document.querySelectorAll('li')
  
      lis.forEach((li=>{
          li.onclick=function(){
             window.open(`/news/city/info/${li.innerText}`)
          }
        }))
  }else if($('body').attr('data-action') == 'city_info'){
    let cityNames=document.querySelectorAll('#city_situation .cityName')

    var url = window.location.href;

    // 提取 info/ 后面的数据
    var startIndex = url.indexOf('info/') + 5; 
    var infoData = url.substring(startIndex);


    cityNames.forEach(v=>{
      v.innerText=decodeURIComponent(infoData)
    })

  }
  else{
    document.querySelector('.back_btn').style.display='flex'
  }
})